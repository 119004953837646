import { EmailTemplate } from "@airjam/types";
import { TemplateNames } from "./templateNames";

export const EMAIL_TEMPLATES: {[key in TemplateNames]: EmailTemplate} = {
    [TemplateNames.OPT]: {
        name: "OTP email",
        subject: "{appName} confirmation code: {code}",
        content: `
        '<div style="width: 100%; min-height: 50px; background-color: #ffffff; text-align: center; padding: 10px;" >'
            '<img style="height: 50px" src="https://airjam.co/favicon.png" ></img>'
        '</div>'
        '<div>'
            '<h2>'
                Confirmation code
            '</h2>'
        '</div>'
        '<div>'
            Welcome to {appName}!'<br></br>'
            Your confirmation code is below - enter it in the browser window where you've started signing up for {appName}.'<br></br>'
            Confirmation code: {code} (It will expire in 10 minutes.)'<br></br>'
            '<br></br>'
            Thanks!'<br></br>'
            {appName} Team
        '</div>'
        '<p></p>'
        '<div>©2024 Creative construct, Inc. All rights reserved</div>'`,
        description: "Email that gets sent when OTP is requested",
    },
    [TemplateNames.ReservationConfirmation]: {
        name: "Reservation Confirmed",
        subject: "Reservation confirmed for <%= it.owner_name %>",
        content: `<div>This reservation is now confirmed</div>
            <div>Need to reschedule? Use the link below to cancel your reservation and book again.\n</div>
            <div><%= it.moderation_url %></div>
            <div>©2024 Creative construct, Inc. All rights reserved</div>`,
        description: "Email that gets sent when an individual reservation is confirmed",
    },
    [TemplateNames.ReservationRejection]: {
        name: "Reservation Rejected",
        subject: "Your reservation has been declined, <%= it.reservation.ownerName %>",
        content: "Your request has been rejected. Please try again next time",
        description: "Email that gets sent when reservation is rejected",
    },
    [TemplateNames.ReservationPending]: {
        name: "Reservation is pending",
        subject: "Your reservation for <%= it.resource.name %> is pending",
        content: "Need to reschedule? Use the link below to cancel your reservation and book again.\n <%= it.moderation_url %>",
        description: "Email that gets sent when an individual reservation is pending",
    },
    [TemplateNames.ReservationCanceled]: {
        name: "Reservation has been canceled",
        subject: "Your reservation for <%= it.resource.name %> has been canceled",
        content: "You have successfully canceled your reservation for <%= it.resource.name %> \n Reservation #: <%= it.reservation.eventId %>",
        description: "Email that gets sent when an individual reservation is canceled",
    },
    [TemplateNames.HostNewReservation]: {
        name: "You have a new reservation",
        subject: "<%= it.reservation.ownerName %> made a reservation for <%= it.resource.name %>",
        content: `<div>This reservation is now confirmed</div>
            <div>Need to reschedule? Use the link below to cancel your reservation and book again.\n</div>
            <div><%= it.moderation_url %></div>
            <div>©2024 Creative construct, Inc. All rights reserved</div>`,
        description: "Email that gets sent to the host when an individual reservation is confirmed",
    },
    [TemplateNames.HostNewReservationPending]: {
        name: "Your new reservation requires your action",
        subject: "New reservation for <%= it.resource.name %> is pending your approval",
        content: "Please use the moderation interface to moderate this new request",
        description: "Email that gets sent to the host when an individual reservation is created for moderation",
    },
    [TemplateNames.HostGuestCanceled]: {
        name: "Guest canceled their reservation",
        subject: "Reservation for <%= it.resource.name %> by <%= it.reservation.ownerName %> has been canceled",
        content: "Reservation <%= it.reservation.eventId %> by <%= it.reservation.ownerName %> has been canceled.",
        description: "Email that gets sent to the host when an individual reservation is canceled",
    },
    [TemplateNames.HostYouCanceled]: {
        name: "You canceled a reservation",
        subject: "Reservation <%= it.reservation.eventId %> has been canceled",
        content: "You canceled reservation <%= it.reservation.eventId %> by <%= it.reservation.ownerName %>",
        description: "Email that gets sent to the host when the host cancels the reservation",
    },
    [TemplateNames.ModerateTimeout]: {
        name: "Reservation has been automatically canceled",
        subject: "moderation is timed out",
        content: "reservation is timed out and will be automatically canceled",
        description: "Email that gets sent when pending reservation is not moderated on time",
    },
};

