import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Container, Grid, Header, Icon } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
interface Props {}

interface States {}
export default class Restaurants extends React.Component<Props, States> {
    render() {
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>

                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/restaurant_landing.jpg" alt="" style={{width: 400}} />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1">
                            Create a website for your restaurant or bar
                            <Header.Subheader>
                                Build a website for your restaurant in seconds
                            </Header.Subheader>
                        </Header>
                            <a href="mailto:@sales@airjam.co">
                                <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                    <Button.Content visible>Contact Us</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </a>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Add a menu or store locator page
                            <Header.Subheader className="subheader_more_margin_top">
                                Link to order or create a page that links from your QR code
                            </Header.Subheader>
                            <br /><br />
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/restaurant_punch.jpg" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>


                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/restaurant_locator.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            Works directly off your spreadsheets
                            <Header.Subheader className="subheader_more_margin_top">
                                Update your store locations instantly by updating your spreadsheet - no need to pay $$$$ for expensive store locators - this is already included with AirJam
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Your privacy and security first
                            <Header.Subheader className="subheader_more_margin_top">
                                Your spreadsheet is protected from public access, and AirJam does not store your data, so your spreadsheet remains solely in your control
                            </Header.Subheader>
                            <br /><br />
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_privacy.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 200 }}>
                    <Grid.Column width={16} textAlign="center" verticalAlign="bottom">
                            <a href="mailto:@sales@airjam.co">
                                <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                    <Button.Content visible>Contact Us</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </a>
                    </Grid.Column>
                </Grid.Row>

            </Grid>


        </Container>;
    }
}