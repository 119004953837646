import React from "react";
import { Header, Icon, Grid, Button, Container, Step } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
interface Props {}

interface States {
    showcaseIdx: number;
    showDemo: boolean;
}
export default class NoCode extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showcaseIdx: 0,
            showDemo: false,
        };
        this.showcaseIncrementAndRepeat();
    }

    render() {
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={1}></Grid.Column>
                    <Grid.Column width={14} verticalAlign="bottom" className="landingCover" >
                        <Grid stackable>
                            <Grid.Row columns={4}>
                                <Grid.Column width={6} verticalAlign={"bottom"}>
                                    <Header textAlign="left" size="huge" className="landingHeading">
                                        <span>App builder for the rest of us</span>
                                        <Header.Subheader className="punchLineSubHeader" style={{ color: "black", marginTop: "20px" }}>
                                            Most beautiful application builder ever made for non-techies
                                        </Header.Subheader>
                                    </Header>
                                    <Button className="landingCTA" onClick={() => { this.setState({showDemo: !this.state.showDemo}); this.forceUpdate(); }}>
                                        {this.state.showDemo ? "Hide me" : "Show me" }
                                    </Button>
                                </Grid.Column>
                                <Grid.Column width={3} verticalAlign={"bottom"}>
                                </Grid.Column>
                                <Grid.Column width={7} className="landingGraphic">
                                    <img alt="" style={{padding: "40px", width: "100%"}} src="/images/landing-top-front.png" className="vert-move" />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} style={{marginBottom: "20px"}} className={this.state.showDemo ? "" : "hideImportant"}>
                                <Grid.Column width={16} textAlign="center" >
                                    <video autoPlay loop muted controls={false} width={"90%"} src="/interface.mov"></video>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={1}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 50 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} textAlign="center">
                        <Header as="h1" textAlign="center" size="large">
                            Run your AirJam app virtually anywhere
                            <Header.Subheader className="subheader_more_margin_top" style={{ marginTop: "20px" }}>
                                AirJam is compatible with a variety of popular platforms including iOS, Android, and web, making your AirJam app accessible virtually everywhere.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12} style={{display: "grid"}}>
                        <div style={{display: "flex"}}>
                            <div className="landingBubble bubbleSplash" style={{width: "65%"}}>
                                <div className="squareText"></div>
                            </div>
                            <div className="landingBubble bubbleSquare" style={{width: "30%"}}>
                                <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                                    Run your application on virtually any devices
                                </Header>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <div className="landingBubble" style={{width: "80%", display: "inline-grid"}}>
                                <div className="rotating_content">
                                <div className="rotating_content__container">
                                    <p className="rotating_content__container__text">
                                    Runs on
                                    </p>
                                    <ul className="rotating_content__container__list">
                                    <li className="rotating_content__container__list__item">Smartphones</li>
                                    <li className="rotating_content__container__list__item">Tablets</li>
                                    <li className="rotating_content__container__list__item">Kiosks</li>
                                    <li className="rotating_content__container__list__item">Large Displays</li>
                                    <li className="rotating_content__container__list__item">Televisions</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display: "flex"}}>
                            <div className="landingBubble tightBubble hideTitleOnMobile" style={{width: "25%"}}>
                                <Step.Group vertical fluid>
                                    <Step active={this.state.showcaseIdx === 0} onClick={() => this.showcase(0)}>
                                    <Icon name="headphones" />
                                    <Step.Content>
                                        <Step.Title>Audio guides</Step.Title>
                                    </Step.Content>
                                    </Step>

                                    <Step active={this.state.showcaseIdx === 1} onClick={() => this.showcase(1)}>
                                    <Icon name="tablet alternate" />
                                    <Step.Content>
                                        <Step.Title>Information kiosks</Step.Title>
                                    </Step.Content>
                                    </Step>

                                    <Step active={this.state.showcaseIdx === 2} onClick={() => this.showcase(2)}>
                                    <Icon name="calendar alternate" />
                                    <Step.Content>
                                        <Step.Title>Event / festival apps</Step.Title>
                                    </Step.Content>
                                    </Step>

                                    <Step active={this.state.showcaseIdx === 3} onClick={() => this.showcase(3)}>
                                    <Icon name="food" />
                                    <Step.Content>
                                        <Step.Title>Digital menu boards</Step.Title>
                                    </Step.Content>
                                    </Step>
                                </Step.Group>
                            </div>
                            {this.showShowcase()}
                        </div>

                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>


                <Grid.Row style={{ minHeight: 50 }}></Grid.Row>
                <Grid.Row columns={4} style={{padding: "30px"}} id="aesthetics">
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%", marginBottom: "20px" }}>
                            You control the aesthetics
                            <Header.Subheader className="subheader_more_margin_top" style={{ marginTop: "20px" }}>
                                With AirJam, you can use our pre-built templates or change code to get ultimate customization capabilities
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_stickers.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4} style={{padding: "30px"}}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_spreadsheets.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%", marginBottom: "20px" }}>
                            Create any application using your own data source
                            <Header.Subheader className="subheader_more_margin_top" style={{ marginTop: "20px" }}>
                                You can manage everything with tools you already use like Google Spreadsheets or Airtable
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 50 }}></Grid.Row>
                <Grid.Row columns={4} style={{padding: "30px"}}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%", marginBottom: "20px" }}>
                            Hands-on service and support
                            <Header.Subheader className="subheader_more_margin_top" style={{ marginTop: "20px" }}>
                                AirJam provides end-to-end application development and service with unmatched turnaround time and pricing. <a href="https://calendly.com/airjam">Contact us</a> to learn more!
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/landing-enterprise.jpg" alt="" style={{ borderRadius: 10 }} />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 50 }}></Grid.Row>
                <Grid.Row columns={4} style={{padding: "30px"}}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_privacy.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%", marginBottom: "20px" }}>
                            <FormattedMessage id="page.view.table.privacy" />
                            <Header.Subheader className="subheader_more_margin_top" style={{ marginTop: "20px" }}>
                                Privacy and security matters with AirJam. With AirJam, you can manage how your user's data are maintained with just a few clicks.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}>
                    <Grid.Column width={16} textAlign="center" verticalAlign="bottom">
                        <a href="https://minjaelee284115.typeform.com/to/hc0tHYWO">
                            <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                <Button.Content visible>Join the waitlist</Button.Content>
                                <Button.Content hidden>
                                    <Icon name="arrow right" />
                                </Button.Content>
                            </Button>
                        </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </Container>;
    }

    private showShowcase = (): JSX.Element => {
        switch (this.state.showcaseIdx) {
            case 0:
                return <div className="landingBubble showcase" style={{width: "70%"}}>
                    <Header as="h2">
                        Build a self-guided tour app from your spreadsheet
                    </Header>
                    <img alt="" src="images/landing/audio-guide.png" className="showcaseImage"></img>
                </div>;
            case 1:
                return <div className="landingBubble showcaseVertical" style={{width: "70%"}}>
                    <img alt="" src="images/landing/kiosk.png" className="showcaseImage"></img>
                    <Header as="h2" textAlign="right">
                        Build interactive kiosks from your spreadsheet in minutes. Update the data on your kiosks in seconds by updating your own spreadsheet
                    </Header>
                </div>;
            case 2:
                return <div className="landingBubble showcase" style={{width: "70%"}}>
                    <Header as="h2" textAlign="right">
                        Make an app for your events to send notifications, highlight speakers, or add to calendars
                    </Header>
                    <img alt="" src="images/landing/events.png" className="showcaseImage"></img>
                </div>;
            default:
                return <div className="landingBubble showcase" style={{width: "70%"}}>
                    <Header as="h2" textAlign="right">
                        Create beautiful menu boards that sync with your POS or spreadsheets - update availability in seconds
                    </Header>
                    <img alt="" src="images/landing/menu.png" className="showcaseImage"></img>
                </div>;
        }
    }

    private showcase = (newIdx: number) => {
        this.setState({
            showcaseIdx: newIdx % 4
        });
    }

    private showcaseIncrementAndRepeat = () => {
        setTimeout(() => {
            this.showcase(this.state.showcaseIdx + 1);
            this.showcaseIncrementAndRepeat();
        }, 8000);
    }
}