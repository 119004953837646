import React from "react";
import { Header, Container } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
interface Props {}

interface States {}
export default class Terms extends React.Component<Props, States> {
    render() {
        return <Container text style={CONTAINER_STYLE}>

            <Header size="medium">Terms of Service</Header>
            <Header sub>Last updated: 1st March 2023</Header>

            <Header size="small">Definitions</Header>
            In this Agreement, except to the extent expressly provided otherwise:

            <ul>
                <li>"Access Credentials" means the usernames, passwords and other credentials enabling access to the Hosted Services, including both access credentials for the User Interface and access credentials for the API;</li>
                <li>"Agreement" means this agreement including any Schedules, and any amendments to this Agreement from time to time;</li>
                <li>"API" means the application programming interface for the Hosted Services defined by the Provider and made available by the Provider to the Customer;</li>
                <li>"Business Day" means any weekday other than a national holiday in United States; "Business Hours" means the hours of 09:00 to 17:00 PST/PDT;</li>
                <li>"Charges" means the following amounts:
                    <ul>
                        <li>the amounts specified in Section 2 of Schedule 1 (Hosted Services particulars);</li>
                        <li>such amounts as may be agreed in writing by the parties from time to time; and</li>
                    </ul>
                </li>
                <li>"Customer Confidential Information" means:
                    <ul>
                        <li>any information disclosed by or on behalf of the Customer to the Provider during the term (whether disclosed in writing, orally or otherwise) that at the time of disclosure:
                            <ul>
                                <li>was marked as "confidential"; or</li>
                                <li>should have been reasonably understood by the Provider to be confidential; and</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>"Customer Data" means all data, works and materials: uploaded to or stored on the Platform by the Customer;</li>
                <li>transmitted by the Platform at the instigation of the Customer; supplied by the Customer to the Provider for uploading to, transmission by or storage on the Platform; or generated by the Platform as a result of the use of the Hosted Services by the Customer (but excluding analytics data relating to the use of the Platform and server log files);</li>
                <li>"Customer Personal Data" means any Personal Data that is processed by the Provider on behalf of the Customer in relation to this Agreement;</li>
                <li>"Data Protection Laws" means the California Consumer Privacy Act (CCPA) and all other applicable laws relating to the processing of Personal Data;</li>
                <li>"Documentation" means the documentation for the Hosted Services produced by the Provider and delivered or made available by the Provider to the Customer;</li>
                <li>"Effective Date" means the date of execution of this Agreement;</li>
                <li>"Force Majeure Event" means an event, or a series of related events, that is outside the reasonable control of the party affected (including failures of the internet or any public telecommunications network, hacker attacks, denial of service attacks, virus or other malicious software attacks or infections, power failures, industrial disputes affecting any third party, changes to the law, disasters, epidemics, pandemics, explosions, fires, floods, riots, terrorist attacks and wars);</li>
                <li>"Hosted Services" means the AirJam's API, as specified in the Hosted Services Specification which will be made available by the Provider to the Customer as a service via the internet in accordance with this Agreement;</li>
                <li>"Hosted Services Defect" means a defect, error or bug in the Platform having an adverse effect on the appearance, operation, functionality or performance of the Hosted Services, but excluding any defect, error or bug caused by or arising as a result of:
                    <ul>
                        <li>any act or omission of the Customer or any person authorized by the Customer to use the Platform or Hosted Services</li>
                        <li>any use of the Platform or Hosted Services contrary to the Documentation, whether by the Customer or by any person authorized by the Customer;</li>
                        <li>a failure of the Customer to perform or observe any of its obligations in this Agreement; and/or</li>
                        <li>an incompatibility between the Platform or Hosted Services and any other system, network, application, program, hardware or software not specified as compatible in the Hosted Services Specification;</li>
                    </ul>
                </li>
                <li>"Hosted Services Specification" means the specification for the Platform and Hosted Services set out in Section 1 of Schedule 1 (Hosted Services particulars) and in the Documentation;</li>
                <li>"Intellectual Property Rights" means all intellectual property rights wherever in the world, whether registrable or unregistrable, registered or unregistered, including any application or right of application for such rights (and these "intellectual property rights" include copyright and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trade marks, service marks, passing off rights, unfair competition rights, patents, petty patents, utility models, semi-conductor topography rights and rights in designs);</li>
                <li>"Personal Data" means personal data under any of the Data Protection Laws;</li>
                <li>"Platform" means the platform managed by the Provider and used by the Provider to provide the Hosted Services, including the system and server software used to provide the Hosted Services, and the computer hardware on which that application, database, system and server software is installed;</li>
                <li>"Schedule" means any schedule attached to the main body of this Agreement; "Services" means any services that the Provider provides to the Customer, or has an obligation to provide to the Customer, under this Agreement;</li>
                <li>"Support Services" means support in relation to the use of, and the identification and resolution of errors in, the Hosted Services, but shall not include the provision of training services;</li>
                <li>"Supported Web Browser" means the current release from time to time of Microsoft Edge, Mozilla Firefox, Google Chrome or Apple Safari, or any other web browser that the Provider agrees in writing shall be supported;</li>
                <li>"Term" means the term of this Agreement, commencing in accordance with Clause 3.1 and ending in accordance with Clause 3.2;</li>
                <li>"User Interface" means the interface for the Hosted Services designed to allow individual human users to access and use the Hosted Services.</li>
            </ul>


            <Header size="small">Hosted Services</Header>
            4.1 The Provider shall provide, or shall ensure that the Platform will provide, to the Customer upon service start date the Access Credentials necessary to enable the Customer to access and use the Hosted Services.<br></br>

            4.2 The Provider hereby grants to the Customer a worldwide, non-exclusive license to use the Hosted Services by means of the API for the internal business purposes of the Customer in accordance with the Documentation during the Term.<br></br>

            4.3 The license granted by the Provider to the Customer under Clause 4.2 is subject to the following limitations: (a) the User Interface may only be used through a Supported Web Browser;<br></br>

            (b) the User Interface may only be used by the officers, employees, agents and subcontractors of the Customer;<br></br>

            4.4 Except to the extent expressly permitted in this Agreement or required by law on a non-excludable basis, the license granted by the Provider to the Customer under Clause 4.2 is subject to the following prohibitions:<br></br>

            (a) the Customer must not sub-license its right to access and use the Hosted Services; (b) the Customer must not permit any unauthorized person or application to access or use the Hosted Services; (c) the Customer must not use the Hosted Services to provide services to third parties; (e) the Customer must not make any alteration to the Platform, except as permitted by the Documentation; and (f) the Customer must not conduct or request that any other person conduct any load testing or penetration testing on the Platform or Hosted Services without the prior written consent of the Provider.<br></br>

            4.5 The Customer shall implement and maintain reasonable security measures relating to the Access Credentials to ensure that no unauthorized person or application may gain access to the Hosted Services by means of the Access Credentials.<br></br>

            4.6 The Provider shall use all reasonable endeavour to maintain the availability of the Hosted Services to the Customer at the gateway between the public internet and the network of the hosting services provider for the Hosted Services but does not guarantee 100% availability.<br></br>

            4.7 For the avoidance of doubt, downtime caused directly or indirectly by any of the following shall not be considered a breach of this Agreement: (a) a Force Majeure Event; (b) a fault or failure of the internet or any public telecommunications network; (c) a fault or failure of the Customer's computer systems or networks; (d) any breach by the Customer of this Agreement; or (e) scheduled maintenance carried out in accordance with this Agreement.<br></br>

            4.8 The Customer must comply with Schedule 2 (Acceptable Use Policy), and must ensure that all persons using the Hosted Services with the authority of the Customer or by means of the Access Credentials comply with Schedule 2 (Acceptable Use Policy).<br></br>

            4.9 The Customer must not use the Hosted Services in any way that causes, or may cause, damage to the Hosted Services or Platform or impairment of the availability or accessibility of the Hosted Services.<br></br>

            4.10 The Customer must not use the Hosted Services in any way that uses excessive Platform resources and as a result is liable to cause a material degradation in the services provided by the Provider to its other customers using the Platform; and the Customer acknowledges that the Provider may use reasonable technical measures to limit the use of Platform resources by the Customer for the purpose of assuring services to its customers generally.<br></br>

            4.11 The Customer must not use the Hosted Services: (a) in any way that is unlawful, illegal, fraudulent or harmful; or (b) in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.<br></br>

            4.12 For the avoidance of doubt, the Customer has no right to access the software code (including object code, intermediate code and source code) of the Platform, either during or after the Term.<br></br>

            4.13 The Provider may suspend the provision of the Hosted Services if any amount due to be paid by the Customer to the Provider under this Agreement is overdue, and the Provider has given to the Customer at least 14 days written notice, following the amount becoming overdue, of its intention to suspend the Hosted Services on this basis.<br></br>

            <Header size="small">Scheduled maintenance</Header>

            5.1 The Provider may from time to time suspend the Hosted Services for the purposes of scheduled maintenance to the Platform, providing that such scheduled maintenance must be carried out in accordance with this Clause 5.<br></br>

            5.2 The Provider shall where practicable give to the Customer at least 5 Business Days prior written notice of scheduled maintenance that will, or is likely to, affect the availability of the Hosted Services or have a material negative impact upon the Hosted Services.<br></br>

            5.3 The Provider shall ensure that all scheduled maintenance is carried out outside Business Hours.<br></br>

            5.4 The Provider shall ensure that, during each calendar month, the aggregate period during which the Hosted Services are unavailable as a result of scheduled maintenance or negatively affected by scheduled maintenance to a material degree, does not exceed 2 hours.<br></br>

            <Header size="small">Support Services</Header>

            6.1 The Provider shall provide the Support Services to the Customer during the Term.<br></br>

            6.2 The Provider shall make available to the Customer a support email address.<br></br>

            6.3 The Provider shall provide the Support Services with reasonable skill and care.<br></br>

            6.4 The Customer may use the support email address for the purposes of requesting and, where applicable, receiving the Support Services; and the Customer must not use the support email for any other purpose.<br></br>

            6.5 The Provider shall respond promptly to all requests for Support Services made by the Customer through the support email.<br></br>

            6.6 The Provider may suspend the provision of the Support Services if any amount due to be paid by the Customer to the Provider under this Agreement is overdue, and the Provider has given to the Customer at least 30 days written notice, following the amount becoming overdue, of its intention to suspend the Support Services on this basis.<br></br>

            <Header size="small">Customer Data</Header>

            7.1 The Customer hereby grants to the Provider a non-exclusive license to copy, reproduce, store, distribute, publish, export, adapt, edit and translate the Customer Data to the extent reasonably required for the performance of the Provider's obligations and the exercise of the Provider's rights under this Agreement. The Customer also grants to the Provider the right to sub-license these rights to its hosting, connectivity and telecommunications service providers, subject to any express restrictions elsewhere in this Agreement.<br></br>

            7.2 The Customer warrants to the Provider that the Customer Data will not infringe the Intellectual Property Rights or other legal rights of any person, and will not breach the provisions of any law, statute or regulation, in any jurisdiction and under any applicable law.<br></br>

            7.3 The Provider shall create a back-up copy of the Customer Data at least daily, shall ensure that each such copy is sufficient to enable the Provider to restore the Hosted Services to the state they were in at the time the back-up was taken, and shall retain and securely store each such copy for a minimum period of 30 days.<br></br>

            <Header size="small">No assignment of Intellectual Property Rights</Header>

            9.1 Nothing in this Agreement shall operate to assign or transfer any Intellectual Property Rights from the Provider to the Customer, or from the Customer to the Provider.

            <Header size="small">Charges</Header>

            10.1 The Customer shall pay the Charges to the Provider in accordance with this Agreement.<br></br>

            10.2 If the Charges are based in whole or part upon the time spent by the Provider performing the Services, the Provider must obtain the Customer's written consent before performing Services that result in any estimate of time-based Charges given to the Customer being exceeded or any budget for time-based Charges agreed by the parties being exceeded; and unless the Customer agrees otherwise in writing, the Customer shall not be liable to pay to the Provider any Charges in respect of Services performed in breach of this Clause 10.2.<br></br>

            10.3 All amounts stated in or in relation to this Agreement are, unless the context requires otherwise, stated inclusive of any applicable value added taxes, which will be added to those amounts and payable by the Customer to the Provider.<br></br>

            10.4 The Provider may elect to vary any element of the Charges by giving to the Customer not less than 30 days written notice of the variation expiring on any anniversary of the date of execution of this Agreement, providing that no such variation shall constitute a percentage increase in the relevant element of the Charges that exceeds 5% over the percentage increase, since the date of the most recent variation of the relevant element of the Charges under this Clause 10.4 (or, if no such variation has occurred, since the date of execution of this Agreement), in the Retail Prices Index (all items) published by the UK Office for National Statistics.<br></br>

            <Header size="small">Payments</Header>

            11.1 The Provider shall issue invoices for the Charges to the Customer on or after the invoicing dates set out in Section 2 of Schedule 1 (Hosted Services particulars).<br></br>

            11.2 The Customer must pay the Charges to the Provider within the period of 30 days following the issue of an invoice in accordance with this Clause 11, providing that the Charges must in all cases be paid before the commencement of the period to which they relate.<br></br>

            11.3 The Customer must pay the Charges by debit card, credit card, direct debit, bank transfer or cheque (using such payment details as are notified by the Provider to the Customer from time to time).<br></br>

            11.4 If the Customer does not pay any amount properly due to the Provider under this Agreement, the Provider may: (a) charge the Customer interest on the overdue amount at the rate of 8% per annum above the Bank of England base rate from time to time (which interest will accrue daily until the date of actual payment and be compounded at the end of each calendar month); or (b) claim interest and statutory compensation from the Customer pursuant to the Late Payment of Commercial Debts (Interest) Act 1998.<br></br>

            <Header size="small">Provider's confidentiality obligations</Header>

            12.1 The Provider must:<br></br>

            (a) keep the Customer Confidential Information strictly confidential;<br></br>

            (b) not disclose the Customer Confidential Information to any person without the Customer's prior written consent, and then only under conditions of confidentiality approved in writing by the Customer;<br></br>

            (c) use the same degree of care to protect the confidentiality of the Customer Confidential Information as the Provider uses to protect the Provider's own confidential information of a similar nature, being at least a reasonable degree of care;<br></br>

            (d) act in good faith at all times in relation to the Customer Confidential Information; and<br></br>

            (e) not use any of the Customer Confidential Information for any purpose other than providing the Hosting Services.<br></br>

            12.2 Notwithstanding Clause 12.1, the Provider may disclose the Customer Confidential Information to the Provider's officers, employees, professional advisers, insurers, agents and subcontractors who have a need to access the Customer Confidential Information for the performance of their work with respect to this Agreement and who are bound by a written agreement or professional obligation to protect the confidentiality of the Customer Confidential Information.<br></br>

            12.3 This Clause 12 imposes no obligations upon the Provider with respect to Customer Confidential Information that:<br></br>

            (a) is known to the Provider before disclosure under this Agreement and is not subject to any other obligation of confidentiality;<br></br>

            (b) is or becomes publicly known through no act or default of the Provider; or<br></br>

            (c) is obtained by the Provider from a third party in circumstances where the Provider has no reason to believe that there has been a breach of an obligation of confidentiality.<br></br>

            12.4 The restrictions in this Clause 12 do not apply to the extent that any Customer Confidential Information is required to be disclosed by any law or regulation, by any judicial or governmental order or request, or pursuant to disclosure requirements relating to the listing of the stock of the Provider on any recognized stock exchange.<br></br>

            12.5 The provisions of this Clause 12 shall continue in force for a period of 2 years following the termination of this Agreement, at the end of which period they will cease to have effect.<br></br>

            <Header size="small">Data protection</Header>

            13.1 Each party shall comply with the Data Protection Laws with respect to the processing of the Customer Personal Data.<br></br>

            13.2 The Customer warrants to the Provider that it has the legal right to disclose all Personal Data that it does in fact disclose to the Provider under or in connection with this Agreement.<br></br>

            13.3 The Customer shall only supply to the Provider, and the Provider shall only process, in each case under or in relation to this Agreement: (a) the Personal Data of data subjects falling within the categories specified in Section 1 of Schedule 3 (Data processing information) (or such other categories as may be agreed by the parties in writing); and (b) Personal Data of the types specified in Section 2 of Schedule 3 (Data processing information) or such other types as may be agreed by the parties in writing).<br></br>

            13.4 The Provider shall only process the Customer Personal Data for the purposes specified in Section 3 of Schedule 3 (Data processing information).<br></br>

            13.5 The Provider shall only process the Customer Personal Data during the Term and for not more than 30 days following the end of the Term, subject to the other provisions of this Clause 13.<br></br>

            13.6 The Provider shall only process the Customer Personal Data on the documented instructions of the Customer including with regard to transfers of the Customer Personal Data to a third country under the Data Protection Laws, as set out in this Agreement or any other document agreed by the parties in writing.<br></br>

            13.7 The Customer hereby authorizes the Provider to make the following transfers of Customer Personal Data:<br></br>

            (a) the Provider may transfer the Customer Personal Data internally to its own employees, offices and facilities, providing that such transfers must be protected by appropriate safeguards;<br></br>

            (b) the Provider may transfer the Customer Personal Data to a country, a territory or sector to the extent that the competent data protection authorities have decided that the country, territory or sector ensures an adequate level of protection for Personal Data.<br></br>

            13.8 The Provider shall promptly inform the Customer if, in the opinion of the Provider, an instruction of the Customer relating to the processing of the Customer Personal Data infringes the Data Protection Laws.<br></br>

            13.9 Notwithstanding any other provision of this Agreement, the Provider may process the Customer Personal Data if and to the extent that the Provider is required to do so by applicable law. In such a case, the Provider shall inform the Customer of the legal requirement before processing, unless that law prohibits such information on important grounds of public interest.<br></br>

            13.10 The Provider shall ensure that persons authorized to process the Customer Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.<br></br>

            13.11 The Provider and the Customer shall each implement appropriate technical and organizational measures to ensure an appropriate level of security for the Customer Personal Data, including those measures specified in Section 4 of Schedule 3 (Data processing information).<br></br>

            13.12 The Provider must not engage any third party to process the Customer Personal Data without the prior specific or general written authorization of the Customer. In the case of a general written authorization, the Provider shall inform the Customer at least 14 days in advance of any intended changes concerning the addition or replacement of any third party processor, and if the Customer objects to any such changes before their implementation, then the Provider must not implement the changes. The Provider shall ensure that each third party processor is subject to equivalent legal obligations as those imposed on the Provider by this Clause 13.<br></br>

            13.13 As at the Effective Date, the Provider is hereby authorized by the Customer to engage, as sub-processors with respect to Customer Personal Data, Section 5 of Schedule 3 (Data processing information).<br></br>

            13.14 The Provider shall, insofar as possible and taking into account the nature of the processing, take appropriate technical and organizational measures to assist the Customer with the fulfillment of the Customer's obligation to respond to requests exercising a data subject's rights under the Data Protection Laws.<br></br>

            13.15 The Provider shall assist the Customer in ensuring compliance with the obligations relating to the security of processing of personal data, the notification of personal data breaches to the supervisory authority, the communication of personal data breaches to the data subject, data protection impact assessments and prior consultation in relation to high-risk processing under the Data Protection Laws. The Provider may charge the Customer at its standard time-based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 13.15.<br></br>

            13.16 The Provider must notify the Customer of any Personal Data breach affecting the Customer Personal Data without undue delay and, in any case, not later than 24 hours after the Provider becomes aware of the breach.<br></br>

            13.17 The Provider shall make available to the Customer all information necessary to demonstrate the compliance of the Provider with its obligations under this Clause 13 and the Data Protection Laws.The Provider may charge the Customer at its standard time- based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 13.17, providing that no such charges shall be levied with respect to the completion by the Provider (at the reasonable request of the Customer, not more than once per calendar year) of the standard information security questionnaire of the Customer.<br></br>

            13.18 The Provider shall, at the choice of the Customer, delete or return all of the Customer Personal Data to the Customer after the provision of services relating to the processing, and shall delete existing copies save to the extent that applicable law requires storage of the relevant Personal Data.<br></br>

            13.19 The Provider shall allow for and contribute to audits, including inspections, conducted by the Customer or another auditor mandated by the Customer in respect of the compliance of the Provider's processing of Customer Personal Data with the Data Protection Laws and this Clause 13. The Provider may charge the Customer at its standard time-based charging rates for any work performed by the Provider at the request of the Customer pursuant to this Clause 13.19, providing that no such charges shall be levied where the request to perform the work arises out of any breach by the Provider of this Agreement or any security breach affecting the systems of the Provider.<br></br>

            13.20 If any changes or prospective changes to the Data Protection Laws result or will result in one or both parties not complying with the Data Protection Laws in relation to processing of Personal Data carried out under this Agreement, then the parties shall use their best endeavors promptly to agree such variations to this Agreement as may be necessary to remedy such non-compliance.<br></br>

            <Header size="small">Warranties</Header>

            14.1 The Provider warrants to the Customer that:<br></br>

            (a) the Provider has the legal right and authority to enter into this Agreement and to perform its obligations under this Agreement;<br></br>

            (b) the Provider will comply with all applicable legal and regulatory requirements applying to the exercise of the Provider's rights and the fulfillment of the Provider's obligations under this Agreement; and<br></br>

            (c) the Provider has or has access to all necessary know-how, expertise and experience to perform its obligations under this Agreement. 14.2 The Provider warrants to the Customer that: (a) the Platform and Hosted Services will conform in all materials respect with the Hosted Services Specification. (b) the Hosted Services will be free from Hosted Services Defects; (c) the Platform will be free from viruses, worms, Trojan horses, ransomware, spyware, adware and other malicious software programs; and (d) the Platform will incorporate security features reflecting the requirements of good industry practice.<br></br>

            14.3 The Provider warrants to the Customer that the Hosted Services, when used by the Customer in accordance with this Agreement, will not breach any laws, statutes or regulations applicable under English law.<br></br>

            14.4 The Provider warrants to the Customer that the Hosted Services, when used by the Customer in accordance with this Agreement, will not infringe the Intellectual Property Rights of any person in any jurisdiction and under any applicable law.<br></br>

            14.5 If the Provider reasonably determines, or any third party alleges, that the use of the Hosted Services by the Customer in accordance with this Agreement infringes any person's Intellectual Property Rights, the Provider may at its own cost and expense: (a) modify the Hosted Services in such a way that they no longer infringe the relevant Intellectual Property Rights; or (b) procure for the Customer the right to use the Hosted Services in accordance with this Agreement.<br></br>

            14.6 The Customer warrants to the Provider that it has the legal right and authority to enter into this Agreement and to perform its obligations under this Agreement.<br></br>

            14.7 All of the parties' warranties and representations in respect of the subject matter of this Agreement are expressly set out in this Agreement. To the maximum extent permitted by applicable law, no other warranties or representations concerning the subject matter of this Agreement will be implied into this Agreement or any related contract.<br></br>

            <Header size="small">Acknowledgements and warranty limitations</Header>

            15.1 The Customer acknowledges that complex software is never wholly free from defects, errors and bugs; and subject to the other provisions of this Agreement, the Provider gives no warranty or representation that the Hosted Services will be wholly free from defects, errors and bugs.<br></br>

            15.2 The Customer acknowledges that complex software is never entirely free from security vulnerabilities; and subject to the other provisions of this Agreement, the Provider gives no warranty or representation that the Hosted Services will be entirely secure.<br></br>

            15.3 The Customer acknowledges that the Hosted Services are designed to be compatible only with that software and those systems specified as compatible in the Hosted Services Specification, and the Provider does not warrant or represent that the Hosted Services will be compatible with any other software or systems.<br></br>

            15.4 The Customer acknowledges that the Provider will not provide any legal, financial, accountancy or taxation advice under this Agreement or in relation to the Hosted Services; and, except to the extent expressly provided otherwise in this Agreement, the Provider does not warrant or represent that the Hosted Services or the use of the Hosted Services by the Customer will not give rise to any legal liability on the part of the Customer or any other person.<br></br>

            <Header size="small">Limitations and exclusions of liability</Header>

            16.1 Nothing in this Agreement will: (a) limit or exclude any liability for death or personal injury resulting from negligence; (b) limit or exclude any liability for fraud or fraudulent misrepresentation; (c) limit any liabilities in any way that is not permitted under applicable law; or (d) exclude any liabilities that may not be excluded under applicable law.<br></br>

            16.2 The limitations and exclusions of liability set out in this Clause 16 and elsewhere in this Agreement: (a) are subject to Clause 16.1; and (b) govern all liabilities arising under this Agreement or relating to the subject matter of this Agreement, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty, except to the extent expressly provided otherwise in this Agreement.<br></br>

            16.3 Neither party shall be liable to the other party in respect of any losses arising out of a Force Majeure Event.<br></br>

            16.4 Neither party shall be liable to the other party in respect of any loss of profits or anticipated savings.<br></br>

            16.5 Neither party shall be liable to the other party in respect of any loss of revenue or income.<br></br>

            16.6 Neither party shall be liable to the other party in respect of any loss of use or production.<br></br>

            16.7 Neither party shall be liable to the other party in respect of any loss of business, contracts or opportunities.<br></br>

            16.8 Neither party shall be liable to the other party respect of any loss or corruption of any data, database or software; providing that this Clause 16.8 shall not protect the Provider unless the Provider has fully complied with its obligations under Clause 7.3 and Clause 7.4. 16.9 Neither party shall be liable to the other party in respect of any special, indirect or consequential loss or damage.<br></br>

            <Header size="small">Force Majeure Event</Header>

            17.1 If a Force Majeure Event gives rise to a failure or delay in either party performing any obligation under this Agreement (other than any obligation to make a payment), that obligation will be suspended for the duration of the Force Majeure Event.<br></br>

            17.2 A party that becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure or delay in that party performing any obligation under this Agreement, must: (a) promptly notify the other; and (b) inform the other of the period for which it is estimated that such failure or delay will continue.<br></br>

            17.3 A party whose performance of its obligations under this Agreement is affected by a Force Majeure Event must take reasonable steps to mitigate the effects of the Force Majeure Event.<br></br>

            <Header size="small">Termination</Header>

            18.1 The Provider may terminate this agreement at any time at their sole discretion.<br></br>

            18.2 Either party may terminate this Agreement immediately by giving written notice of termination to the other party if the other party commits a material breach of this Agreement.<br></br>

            18.3 Subject to applicable law, either party may terminate this Agreement immediately by giving written notice of termination to the other party if:<br></br>

            (a) the other party: (i) is dissolved; (ii) ceases to conduct all (or substantially all) of its business; (iii) is or becomes unable to pay its debts as they fall due; (iv) is or becomes insolvent or is declared insolvent; or (v) convenes a meeting or makes or proposes to make any arrangement or composition with its creditors;<br></br>

            (b) an administrator, administrative receiver, liquidator, receiver, trustee, manager or similar is appointed over any of the assets of the other party;<br></br>

            (c) an order is made for the winding up of the other party, or the other party passes a resolution for its winding up (other than for the purpose of a solvent company reorganization where the resulting entity will assume all the obligations of the other party under this Agreement); or<br></br>

            (d) if that other party is an individual: (i) that other party dies; (ii) as a result of illness or incapacity, that other party becomes incapable of managing his or her own affairs; or (iii) that other party is the subject of a bankruptcy petition or order.<br></br>

            <Header size="small">Effects of termination</Header>

            19.1 Upon the termination of this Agreement, all of the provisions of this Agreement shall cease to have effect, save that the following provisions of this Agreement shall survive and continue to have effect (in accordance with their express terms or otherwise indefinitely): Clauses 1, 4.12, 8, 11.2, 11.4, 12, 13, 16, 19, 22 and 23.<br></br>

            19.2 Except to the extent expressly provided otherwise in this Agreement, the termination of this Agreement shall not affect the accrued rights of either party.<br></br>


        </Container>;
    }
}