import { Calendar } from "../../../models/Calendar";
import { Form, Button, Grid, Popup, Icon, Image, Container, Header, Checkbox, List, Radio, Dropdown } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import connectAllProps from "../../../shared/connect";
import { PrimitiveType } from "intl-messageformat";
import WarningModal from "../shared/WarningModal";
import { ComponentProps } from "../../../shared/ComponentProps";
import GoogleCalendarCalendarSelector from "../components/GoogleCalendarCalendarSelector";
import CalendarReservationType from "../../../models/CalendarReservationType";
import CalendarEventVisibility from "../../../models/CalendarEventVisibility";
import AuthProvider from "../../../models/AuthProvider";
import CalendarVisibility from "../../../models/CalendarVisibility";
import CalendarManagementMode from "../../../models/CalendarManagementMode";
import PaymentProcessor from "../../../models/PaymentProcessor";
import { CustomSubmitValidator } from "../../../models/CustomSubmitValidator";
import CalendarResourceEditor from "./CalendarResourceEditor";
import CalendarEventMarketingOptions from "../../../models/CalendarEventMarketingOptions";
import { CalendarInstance, CalendarResourceFieldProperty, EmailTemplate, PrivateCalendarResource } from "@airjam/types";
import ComponentTranslationEditor from "../translation/ComponentTranslationEditor";
import { ComponentTranslation, Translation, CALENDAR_DEFAULT_TRANSLATIONS } from "@airjam/types";

import "codemirror/lib/codemirror.css";
import "../../css/tui-editor-override.css";
import EmailTemplateEditor from "../components/EmailTemplateEditor";
import { EMAIL_TEMPLATES } from "../../../shared/templates/email";
import { TemplateNames } from "../../../shared/templates/templateNames";

const timezoneData = require("timezones.json");
const emailTemplates: TemplateNames[] = [TemplateNames.ReservationConfirmation, TemplateNames.ReservationRejection, TemplateNames.ReservationPending, TemplateNames.ReservationCanceled, TemplateNames.HostNewReservation, TemplateNames.HostNewReservationPending, TemplateNames.HostGuestCanceled, TemplateNames.HostYouCanceled, TemplateNames.ModerateTimeout];
const allEmailTemplates = EMAIL_TEMPLATES;
const authBypassStubPlaceholder = `{ "id": "12", "name": "John Appleseed", "email" : "john@appleseed.com", "emailVerified" : true, "provider" : "NONE" }`;

interface Props extends ComponentProps {
    calendar?: Calendar;
    submitTextId: string;
    onSubmit: (calendar: Calendar) => void;
    loading?: boolean;
    locale?: string;
}

enum PaneSelection {
    GeneralPane = "general",
    ResourcesPane = "resources",
    LocalizationPane = "localization",
    EmailTemplatesPane = "emailTemplates",
    AdvancedPane = "advanced"
}

interface States {
    editing: boolean;
    openClearEditWarning: boolean;
    pane: PaneSelection;
    isReservable: CalendarReservationType;
    eventVisibility: CalendarEventVisibility;
    calendarVisibility: CalendarVisibility;
    authenticationProvider: AuthProvider;
    authCustomEndpoint: string;
    authBypass: boolean;
    managementMode: CalendarManagementMode;
    eventMarketingToolsEnabled: CalendarEventMarketingOptions;
    paymentProcessor: PaymentProcessor;
    timezone: string;
    currentResourceIndex: number;
    resourceFieldProperties: {[fieldName: string]: boolean};
    translations: ComponentTranslation;
    selectedEmailTemplate: string;
    emailTemplates: {[id: string]: EmailTemplate};
}

class CalendarEditor extends React.Component<Props, States> {
    private nameRef: RefObject<HTMLInputElement>;
    private descriptionRef: RefObject<any>;
    private authConfigScheduleRef: RefObject<HTMLInputElement>;
    private authConfigTicketingRef: RefObject<HTMLInputElement>;
    private authBypassStubRef: RefObject<HTMLTextAreaElement>;
    private submissionValidationEndpointRef: RefObject<HTMLInputElement>;
    private paymentProcessorTokenRef: RefObject<HTMLInputElement>;
    private originalName: string = "";
    private originalEventVisibility: CalendarEventVisibility = CalendarEventVisibility.Visible;
    private originalDescription: string = "";
    private originalTimezone: string = "";
    private originalIsReservable: CalendarReservationType = CalendarReservationType.NotReservable;
    private originalCalendarInstance: string = "";
    private originalCalendarVisibility: CalendarVisibility = CalendarVisibility.Public;
    private originalAuthProvider: AuthProvider = AuthProvider.None;
    private originalAuthBypass: boolean = false;
    private originalAuthBypassStub: string = "";
    private originalManagementMode: CalendarManagementMode = CalendarManagementMode.Singleton;
    private originalEventMarketingTools: CalendarEventMarketingOptions = CalendarEventMarketingOptions.Disabled;
    private originalAuthConfig: string = "";
    private originalAuthCustomEndpoint: string = "";
    private originalPaymentProcessor: PaymentProcessor = PaymentProcessor.None;
    private originalPaymentProcessorToken: string = "";
    private originalSubmissionValidationEndpoint: string = "";
    private originalResources: PrivateCalendarResource[] = [];
    private originalResourceFieldEnabledProperties: {[fieldName: string]: boolean} = {};
    private resources: PrivateCalendarResource[] = [];
    private originalResourceMapping: number[] = [];
    private originalTranslations: ComponentTranslation = {} as ComponentTranslation;
    private originalEmailTemplates: {[id: string]: EmailTemplate} = {};
    private resourceEditors: any[] = [];
    private resourceMapping: number[] = [];
    private calendarInstances: CalendarInstance[] = [];
    private timezones: string[] = [];

    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.nameRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.authConfigScheduleRef = React.createRef();
        this.authConfigTicketingRef = React.createRef();
        this.authBypassStubRef = React.createRef();
        this.paymentProcessorTokenRef = React.createRef();
        this.submissionValidationEndpointRef = React.createRef();
        this.getString = this.props.intl.formatMessage;

        this.resources = this.props.calendar && this.props.calendar.resources ? this.props.calendar.resources : [];
        if (!this.resources.length) this.resources.push(this.newResource());
        this.resources.forEach((resource, idx) => {
            this.resourceEditors.push(
                <CalendarResourceEditor instanceId={idx} resource={resource} onResourceUpdated={this.resourceUpdated} locale={this.props.locale ? this.props.locale : navigator.language}></CalendarResourceEditor>
            );
            this.resourceMapping.push(idx);
        });
        const newTimezoneData: string[] = [];
        timezoneData.forEach((tzDefinition: any) => tzDefinition.utc.forEach((tzName: string) => {
            // remove any duplicates
            if (newTimezoneData.indexOf(tzName) < 0) newTimezoneData.push(tzName);
        }));
        this.timezones.length = 0;
        this.timezones.push(...(newTimezoneData).sort());

        this.state = {
            editing: false,
            openClearEditWarning: false,
            pane: PaneSelection.GeneralPane,
            timezone: this.props.calendar ? this.props.calendar.timezone : "",
            isReservable: this.props.calendar ? this.props.calendar.isReservable : CalendarReservationType.NotReservable,
            eventVisibility: this.props.calendar ? this.props.calendar.eventVisibility : CalendarEventVisibility.Visible,
            calendarVisibility: this.props.calendar ? this.props.calendar.calendarVisibility : CalendarVisibility.Public,
            authenticationProvider: this.props.calendar ? this.props.calendar.authProvider : AuthProvider.None,
            authBypass: this.props.calendar ? this.props.calendar.authBypass : false,
            managementMode: this.props.calendar ? this.props.calendar.managedBy : CalendarManagementMode.Singleton,
            eventMarketingToolsEnabled: this.props.calendar ? this.props.calendar.eventMarketingTools : CalendarEventMarketingOptions.Disabled,
            paymentProcessor: this.props.calendar ? this.props.calendar.paymentProcessor : PaymentProcessor.None,
            emailTemplates: this.props.calendar && this.props.calendar.emailTemplates ? this.props.calendar.emailTemplates : {},
            selectedEmailTemplate: "",
            translations: this.props.calendar ? this.props.calendar.translations : {} as ComponentTranslation,
            resourceFieldProperties: this.props.calendar ? this.getResourceFieldEnabledMap(this.props.calendar.resourceFieldProperties) : {},
            currentResourceIndex: 0,
            authCustomEndpoint: this.props.calendar ? this.props.calendar.authCustomEndpoint : "",
        };
    }
    componentDidMount() {
    }
    render(): React.ReactElement<any> {
        // Set timezone and load all timezones
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        if (this.props.calendar) {
            this.originalName = this.props.calendar.name;
            this.originalDescription = this.props.calendar.description;
            this.originalTimezone = this.props.calendar.timezone ? this.props.calendar.timezone : timeZone;
            this.originalIsReservable = this.props.calendar.isReservable;
            this.originalEventVisibility = this.props.calendar.eventVisibility;
            this.originalCalendarInstance = this.props.calendar.instances.length ? this.props.calendar.instances[0].id : "";
            this.originalCalendarVisibility = this.props.calendar.calendarVisibility;
            this.originalAuthProvider = this.props.calendar.authProvider;
            this.originalAuthBypass = this.props.calendar.authBypass;
            this.originalAuthBypassStub = this.props.calendar.authBypassStub;
            this.originalManagementMode = this.props.calendar.managedBy;
            this.originalEventMarketingTools = this.props.calendar.eventMarketingTools;
            this.originalAuthConfig = this.props.calendar.authConfig;
            this.originalAuthCustomEndpoint = this.props.calendar.authCustomEndpoint;
            this.originalPaymentProcessor = this.props.calendar.paymentProcessor;
            this.originalPaymentProcessorToken = this.props.calendar.paymentProcessorToken;
            this.originalSubmissionValidationEndpoint = this.props.calendar.submissionValidation && this.props.calendar.submissionValidation.validationEndpoint ? this.props.calendar.submissionValidation.validationEndpoint : "";
            this.originalResources = this.props.calendar.resources;
            this.originalResourceMapping = this.resourceMapping;
            this.originalTranslations = this.props.calendar.translations;
            this.originalResourceFieldEnabledProperties = this.getResourceFieldEnabledMap(this.props.calendar.resourceFieldProperties);
            this.originalEmailTemplates = this.props.calendar.emailTemplates ? this.props.calendar.emailTemplates : {};
        }
        return (
            <Form>
                <Grid columns={2} divided>
                    <Grid.Row stretched className="fullHeight">
                        <Grid.Column width={3} className="leftPanel">
                            {this.renderLhs()}
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <div>
                                <br /><br />
                                {this.renderGeneralSettings()}
                                {this.renderLocalizationPane()}
                                {this.renderEmailTemplatesPane()}
                                {this.renderAdvancedPane()}
                                {this.renderResources()}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            {this.renderClearEditWarningModal()}
            </Form>
        );
    }

    private renderLocalizationPane = (): JSX.Element => {
        return <Grid className={this.state.pane !== PaneSelection.LocalizationPane ? "hideImportant" : ""}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <ComponentTranslationEditor {...this.props}
                        translation={this.state.translations}
                        defaultTranslation={CALENDAR_DEFAULT_TRANSLATIONS}
                        onChange={(updatedTranslation: ComponentTranslation) => {
                            this.setState({
                                translations: updatedTranslation
                            }, this.onEditing);
                        }} />
                </Grid.Column>
            </Grid.Row>
        </Grid>;
    }

    private renderEmailTemplatesPane = (): JSX.Element => {
        const eligibleTemplates: string[] = [];
        emailTemplates.forEach(t => {
            if (allEmailTemplates[t]) eligibleTemplates.push(t);
        });
        const emailTemplateOptions = eligibleTemplates.map(te => { return { key: te, text: allEmailTemplates[(te as TemplateNames)].name, value: te }; });
        return <Grid className={this.state.pane !== PaneSelection.EmailTemplatesPane ? "hideImportant" : ""}>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Dropdown
                        button className="icon" floating labeled icon="mail" options={emailTemplateOptions}
                        search text="Select an email template to manage"
                        onChange={(e, t) => { this.setState({selectedEmailTemplate: String(t.value)}); }}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    {eligibleTemplates.map(template => {
                        const uniqueKey = "email-template-" + template;
                        return <div key={uniqueKey + "-container"} className={this.state.selectedEmailTemplate !== template ? "hideImportant" : ""}>
                            <EmailTemplateEditor
                                key={uniqueKey}
                                template={this.state.emailTemplates[template] ? this.state.emailTemplates[template] : {}}
                                defaultTemplate={allEmailTemplates[(template as TemplateNames)]}
                                onTemplateUpdated={(updated: EmailTemplate) => {
                                    const templates = this.state.emailTemplates;
                                    templates[template] = updated;
                                    this.setState({emailTemplates: templates});
                                    this.onEditing();
                                }}
                                />
                        </div>;
                    })}
                </Grid.Column>
            </Grid.Row>
        </Grid>;
    }

    private renderAdvancedPane = (): JSX.Element => {
        return <Grid className={this.state.pane !== PaneSelection.AdvancedPane ? "hideImportant" : ""}>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Custom Submission Validation&nbsp;
                        <Popup content="Please provide a REST endpoint for custom booking validations" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Custom REST endpoint for validating booking submissions.
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    This endpoint must return HTTP code 200 for successful validation. Please refer to this API documentation for details of expected return codes.
                    <br />
                    <input ref={this.submissionValidationEndpointRef} placeholder="https://" defaultValue={this.originalSubmissionValidationEndpoint} onChange={this.onEditing} />
                </Grid.Column>
            </Grid.Row>

            <Grid.Row className={this.state.managementMode !== CalendarManagementMode.Decentralized ? "hideImportant" : ""}>
                <Grid.Column width={5}>
                    <span className="section label">
                        Enabled Fields&nbsp;
                        <Popup content="Please provide a REST endpoint for custom booking validations" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Control which fields are available to your users to manage.
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    Please select which features are available for your users to configure<br/>
                    Resource Address: <Radio toggle checked={this.isResourceFieldEnabled("staticAddress")}
                        onChange={(e, data) => this.setResourceEnabledField("staticAddress", data.checked === true)} /> <br/>
                    Booking Duration: <Radio toggle checked={this.isResourceFieldEnabled("reservableUntil")}
                        onChange={(e, data) => this.setResourceEnabledField("reservableUntil", data.checked === true)} /> <br/>
                    Reservation Window: <Radio toggle checked={this.isResourceFieldEnabled("reservationWindow")}
                        onChange={(e, data) => this.setResourceEnabledField("reservationWindow", data.checked === true)} /> <br/>
                    Operating Hours: <Radio toggle checked={this.isResourceFieldEnabled("operatingHours")}
                        onChange={(e, data) => this.setResourceEnabledField("operatingHours", data.checked === true)} /> <br/>
                    Sync Sources: <Radio toggle checked={this.isResourceFieldEnabled("dataSync")}
                        onChange={(e, data) => this.setResourceEnabledField("dataSync", data.checked === true)} /> <br/>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Authentication Bypass&nbsp;
                        <Popup content="Authentication bypass allows the bucket to bypass the authentication settings and returns settings specified below. If settings are unspecified, the bypass will return a static authentication result." trigger={<Icon name="lock open" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        If enabled, AirJam will bypass authentication checks and return the payload below.
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    Enable authentication bypass: <Radio toggle checked={this.state.authBypass}
                        onChange={(e, data) => this.setState({ authBypass: data.checked === true })} />
                    <br /><br />
                    Stubbed payload to return to authentication calls. If left empty, a default static payload will be returned. <br />
                    <textarea ref={this.authBypassStubRef} placeholder={authBypassStubPlaceholder} defaultValue={this.originalAuthBypassStub} onChange={this.onEditing} />
                </Grid.Column>
            </Grid.Row>

        </Grid>;
    }

    private renderLhs = (): JSX.Element => {
        return <List relaxed>
            <List.Item>
                <List.Content>
                    <List.Header>{this.nameRef.current ? this.nameRef.current.value : this.originalName}</List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name="calendar alternate outline" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.pane === PaneSelection.GeneralPane ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.GeneralPane }); }}>General</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name="language" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.pane === PaneSelection.LocalizationPane ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.LocalizationPane }); }}>Translations</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            <List.Item>
                <List.Icon name="mail" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.pane === PaneSelection.EmailTemplatesPane ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.EmailTemplatesPane }); }}>Email Messages</span>
                    </List.Header>
                </List.Content>
            </List.Item>
            { (this.state.isReservable === CalendarReservationType.Reservable) && (this.state.managementMode === CalendarManagementMode.Singleton) ? this.renderResourcesNavigation() : ""}
            { this.state.isReservable === CalendarReservationType.Reservable ?
                <List.Item>
                    <List.Icon name="magic" />
                    <List.Content>
                        <List.Header>
                            <span className={this.state.pane === PaneSelection.AdvancedPane ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.AdvancedPane }); }}>Advanced</span>
                        </List.Header>
                    </List.Content>
                </List.Item> :
            ""}

            <List.Item>
                <List.Content>
                    <List.Header>
                        <Button fluid onClick={this.onSubmit} color="orange"
                            loading={this.props.loading}
                            disabled={this.props.loading || !this.state.editing}>
                            <FormattedMessage id={this.props.submitTextId} />
                        </Button>
                        <div style={{height: "5px"}}></div>
                        <Button fluid onClick={() => this.setState({openClearEditWarning: true})}
                            loading={this.props.loading}
                            disabled={this.props.loading || !this.state.editing}>
                            <FormattedMessage id="component.button.clear_edit" />
                        </Button>
                    </List.Header>
                </List.Content>
            </List.Item>

        </List>;
    }

    private renderResourcesNavigation = (): JSX.Element => {
        return <List.Item>
                <List.Icon name="clone outline" />
                <List.Content>
                    <List.Header>
                        <span className={this.state.pane === PaneSelection.ResourcesPane ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.ResourcesPane }); }}>Resources</span>
                    </List.Header>
                </List.Content>
                <List.List>
                    {
                        this.resourceMapping.map((resourceIndex, index) => {
                            if (this.resources.length <= resourceIndex) return "";
                            return <List.Item key={index}>
                                <List.Icon name="target" />
                                <List.Content>
                                    <List.Header>
                                        <span className={this.state.pane === PaneSelection.ResourcesPane && this.state.currentResourceIndex === resourceIndex ? "navigationLabel current" : "navigationLabel"} onClick={() => {this.setState({pane: PaneSelection.ResourcesPane, currentResourceIndex: resourceIndex }); }}>
                                            {this.resources[resourceIndex].name ? this.resources[resourceIndex].name : "New Resource"}
                                        </span>
                                        &nbsp;
                                        {this.resourceMapping.length > 1 ? <span style={{cursor: "pointer"}} onClick={() => this.deleteResourceConfirmation(index)}><Icon name="trash alternate outline" size="small" corner="top left" /></span> : "" }
                                    </List.Header>
                                </List.Content>
                            </List.Item>;
                        })
                    }
                    <List.Item>
                        <List.Icon name="plus" />
                        <List.Content>
                            <List.Header>
                                <span className="navigationLabel" onClick={() => this.addNewResource()}>Add a new resource</span>
                            </List.Header>
                        </List.Content>
                    </List.Item>
                </List.List>
            </List.Item>;
    }

    private renderGeneralSettings = (): JSX.Element => {
        return <Grid className={!this.state.pane || (this.state.pane !== PaneSelection.GeneralPane) ? "hideImportant" : ""}>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Component Name&nbsp;
                        <Popup content="Please provide a distinct and easily identifiable name for your calendar component" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Name of the calendar component
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    <input ref={this.nameRef} autoFocus={true}
                        defaultValue={this.originalName}
                        onChange={this.onEditing}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Component Description&nbsp;
                        <Popup content="This description is only shown on administrative consoles to identify the component" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Please describe the role of your calendar component
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    <input ref={this.descriptionRef}
                        defaultValue={this.originalDescription}
                        onChange={this.onEditing}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Default Timezone&nbsp;
                        <Popup content="This timezone setting is used to specify timezone-specific settings, such as operating hours." trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Select default timezone to configure and display times for this component
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    <select name="timezoneSelector" defaultValue={this.originalTimezone} onChange={e => {this.setState({ timezone: e.target.value }); this.onEditing(); }}>
                        <option key={this.originalTimezone}>{this.originalTimezone.replace("_/g", " ")}</option>
                        {
                            this.timezones.map((zone: string) => {
                                return <option key={zone}>{zone.replace("_/g", " ")}</option>;
                            })
                        }
                    </select>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={5}>
                    <span className="section label">
                        Calendar Type&nbsp;
                        <Popup content="Select the behavior" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Select how your calendar component will be used as
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    <Popup content="Display Events Only" trigger={
                        <Container className={this.state.isReservable !== CalendarReservationType.Reservable ? "templateOptionContainer selected" : "templateOptionContainer"} onClick={() => { this.setState({isReservable: CalendarReservationType.NotReservable}); }} >
                            <Image src="/images/event-schedule.png"></Image>
                            <Header size="small">Calendar & Schedules</Header>
                        </Container>
                    } />
                    <Popup content="Allow users to book events" trigger={
                        <Container className={this.state.isReservable === CalendarReservationType.Reservable ? "templateOptionContainer selected" : "templateOptionContainer"} onClick={() => { this.setState({isReservable: CalendarReservationType.Reservable}); }} >
                            <Image src="/images/event-type.png"></Image>
                            <Header size="small">RSVPs & Reservations</Header>
                        </Container>
                    } />
                </Grid.Column>
            </Grid.Row>
            {this.renderTicketingOptions()}
            {this.renderEventCalendar()}
        </Grid>;
    }

    private renderTicketingOptions = (): JSX.Element => {
        return <>
                <Grid.Row className={this.state.isReservable !== CalendarReservationType.Reservable ? "hideImportant" : ""}>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Event Visibility&nbsp;
                            <Popup content="Select display scheduled events to make the calendar act like an open calendar, or select hide scheduled events to allow users to book only between the free times" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Control the visibility of scheduled events in this calendar
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Checkbox toggle fitted checked={this.state.eventVisibility === CalendarEventVisibility.Visible} onChange={(e, data) => {
                            this.setState({ eventVisibility: data.checked ? CalendarEventVisibility.Visible : CalendarEventVisibility.Hidden });
                        }}/>
                        <span style={{marginLeft: "10px", verticalAlign: "super"}}>{ this.state.eventVisibility === CalendarEventVisibility.Visible ? "All scheduled events are visible to public" : "Events are hidden from the public" }</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className={this.state.isReservable !== CalendarReservationType.Reservable ? "hideImportant" : ""}>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Authentication Provider&nbsp;
                            <Popup content="Choose how your component users will identify and authenticate themselves" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Choose how your authentication provider for the component to interact with
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {this.renderAuthProviderSelection("ticketing", this.authConfigTicketingRef)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className={this.state.isReservable !== CalendarReservationType.Reservable ? "hideImportant" : ""}>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Payment Processor&nbsp;
                            <Popup content="You can choose and integrate with a payment processor that you use to charge for your events" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Choose a payment processing provider for the paid events
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name="paymentProcessor" defaultValue={this.originalPaymentProcessor} onChange={e => {this.setState({ paymentProcessor: e.target.value as PaymentProcessor}); }}>
                            <option value={PaymentProcessor.None}>No payments collected</option>
                            <option value={PaymentProcessor.Stripe}>Stripe</option>
                            <option value={PaymentProcessor.Paypal}>PayPal</option>
                        </select>
                        <div className={!this.state.paymentProcessor || (this.state.paymentProcessor === PaymentProcessor.None) ? "hideImportant" : "" }>
                            <br />
                            Provide processor API key for the given payment processor
                            <br />
                            <input ref={this.paymentProcessorTokenRef} defaultValue={this.originalPaymentProcessorToken} onChange={this.onEditing} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className={this.state.isReservable !== CalendarReservationType.Reservable ? "hideImportant" : ""}>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Resource Management&nbsp;
                            <Popup content="Select centralized if all calendars and resources are managed by the component owner, select user managed if users are allowed to create, manage, and moderate calendars and resources" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Select who manages calendars and resources
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Popup content="Single account manages all calendars and resources" trigger={
                            <Button icon active={this.state.managementMode === CalendarManagementMode.Singleton} className={"iconButton big"} onClick={() => {this.setState({ managementMode: CalendarManagementMode.Singleton}); }}>
                                <i className={"fas fa-user fa-2x"}></i>
                                <div>Admin Managed</div>
                            </Button>
                        } />
                        <Popup content="Users are allowed to create and manage their own calendars and resources to book" trigger={
                            <Button icon active={this.state.managementMode === CalendarManagementMode.Decentralized} className={"iconButton big"} onClick={() => {this.setState({ managementMode: CalendarManagementMode.Decentralized}); }}>
                                <i className={"fas fa-users fa-2x"}></i>
                                <div>User Managed</div>
                            </Button>
                        } />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className={this.state.isReservable !== CalendarReservationType.Reservable ? "hideImportant" : ""}>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Automated Event Marketing Tools&nbsp;
                            <Popup content="Choose if you'd like to enable AI-based event marketing tools to your calendar events" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Enable automated event marketing tools
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name="eventMarketingTools" defaultValue={this.originalEventMarketingTools} onChange={e => {this.setState({ eventMarketingToolsEnabled: e.target.value as CalendarEventMarketingOptions}); }}>
                            <option value={CalendarEventMarketingOptions.Disabled}>Disabled</option>
                            <option value={CalendarEventMarketingOptions.Enabled}>Enabled</option>
                        </select>
                    </Grid.Column>
                </Grid.Row>
        </>;
    }

    private newResource = (): PrivateCalendarResource => {
        return {} as PrivateCalendarResource;
    }

    private addNewResource = (): void => {
        this.resources.push(this.newResource());
        this.resourceEditors.push(<CalendarResourceEditor instanceId={this.resources.length - 1} resource={this.resources[this.resources.length - 1]} onResourceUpdated={this.resourceUpdated} ></CalendarResourceEditor>);
        this.resourceMapping.push(this.resources.length - 1);
        this.forceUpdate();
    }

    private resourceUpdated = (instanceId: number, updatedResource: PrivateCalendarResource) => {
        if (instanceId >= this.resources.length) return; // do nothing on erroneous resource indices
        // console.log(updatedResource);
        this.resources[instanceId] = updatedResource;
        this.forceUpdate();
    }

    private deleteResourceConfirmation = (index: number): void => {
        // TODO -- show confirmation here.
        if (!this.resourceMapping || this.resourceMapping.length <= index) return;
        const mappingIdx = this.resourceMapping[index];
        if (!this.resources || this.resources.length <= mappingIdx) return;
        if (!this.resourceEditors || this.resourceEditors.length <= mappingIdx) return;
        this.resourceMapping.splice(index, 1);
        this.forceUpdate();
    };

    private renderResources = (): JSX.Element => {
        return <div className={this.state.pane !== PaneSelection.ResourcesPane ? "hideImportant" : ""}>
            {this.resourceEditors.map((editor, index) => {
                return <div key={"editor-" + index} className={this.state.currentResourceIndex !== index ? "hideImportant" : ""}>{editor}</div>;
            })}
        </div>;
    }

    private renderEventCalendar = (): JSX.Element => {
        return <>
            <Grid.Row className={this.state.isReservable === CalendarReservationType.Reservable ? "hideImportant" : ""}>
                <Grid.Column width={5}>
                    <span className="section label">
                        Calendar Data Source&nbsp;
                        <Popup content="You must log into AirJam using a related Google account and grant AirJam permission to view your calendars to see the list of your calendars from Google" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Select a calendar from the list on right to sync with an external calendar data source
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    <GoogleCalendarCalendarSelector {...this.props} onChange={this.calendarSelectionChanged} componentId={this.props.calendar?.calendarComponentId} initialKey={this.originalCalendarInstance} key={"doc.selector.calendar"} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={this.state.isReservable === CalendarReservationType.Reservable ? "hideImportant" : ""}>
                <Grid.Column width={5}>
                    <span className="section label">
                        Authentication Provider&nbsp;
                        <Popup content="Choose how your component users will identify and authenticate themselves" trigger={<Icon name="question circle" size="small" />} />
                    </span>
                    <label className="section label subtext">
                        Choose how your authentication provider for the component to interact with
                    </label>
                </Grid.Column>
                <Grid.Column width={11}>
                    {this.renderAuthProviderSelection("event", this.authConfigScheduleRef)}
                </Grid.Column>
            </Grid.Row>
        </>;
    }

    private renderAuthProviderSelection = (key: string, authConfigRef: RefObject<HTMLInputElement>): JSX.Element => {
        return <div key={"authprovider-" + key}>
            <select name="authProviderSelect" key={"authprovider-select-" + key} defaultValue={this.originalAuthProvider} onChange={e => { this.setState({ authenticationProvider: e.target.value as AuthProvider}); }} >
                <option value={AuthProvider.None}>No Authentication</option>
                <option value={AuthProvider.Firebase}>Google Firebase</option>
                <option value={AuthProvider.Clerk}>Clerk</option>
                <option value={AuthProvider.Custom}>Custom Authentication Endpoint</option>
            </select>
            <br />
            { this.state.authenticationProvider === AuthProvider.Firebase ?
                <span>
                    Copy and base your Firebase authentication string down below
                </span>
            : "" }
            { this.state.authenticationProvider === AuthProvider.Clerk ?
                <span>
                    Copy and base your Clerk identity string down below
                </span>
            : "" }
            { this.state.authenticationProvider === AuthProvider.Custom ?
                <span>
                    Please provide secret payload your custom authenticator accepts for its authentication flow below
                </span>
            : "" }
            <span className={!this.state.authenticationProvider || (this.state.authenticationProvider === AuthProvider.None) ? "hideImportant" : ""}>
                <input key={"authprovider-config-" + key} ref={authConfigRef} defaultValue={this.originalAuthConfig} onChange={this.onEditing} />
                <span className={this.state.authenticationProvider !== AuthProvider.Custom ? "hideImportant" : ""}>
                    <span>
                        Please provide the REST endpoint to validate authentications with. The endpoint must return HTTP code 200 for successful authentication with permission settings as described in our API documentation.
                    </span>
                    <input placeholder="https://" defaultValue={this.originalAuthCustomEndpoint} onChange={(e) => {
                        this.setState({authCustomEndpoint: e.target.value});
                        this.onEditing();
                    }} />
                </span>

                <br /> <br />
                <span className="section label">
                    Calendar Visibility&nbsp;
                    <Popup content="This describes who can see and use your calendar" trigger={<Icon name="question circle" size="small" />} />
                </span>
                You can choose who can see and book your events based on their authentication settings
                <br /><br />
                <Popup content="Anyone can see and book events in this calendar" trigger={
                    <Button icon key={"authprovider-button-public-" + key} active={this.state.calendarVisibility === CalendarVisibility.Public} className={"iconButton big"} onClick={() => this.setState({calendarVisibility: CalendarVisibility.Public})}>
                        <i className={"fas fa-lock-open fa-2x"}></i>
                        <div>Open</div>
                    </Button>
                } />
                <Popup content="Anyone can see the events but only authenticated users can book" trigger={
                    <Button icon key={"authprovider-button-auth-to-book-" + key} active={this.state.calendarVisibility === CalendarVisibility.AuthToBook} className={"iconButton big"} onClick={() => this.setState({calendarVisibility: CalendarVisibility.AuthToBook})}>
                        <i className={"fas fa-user-shield fa-2x"}></i>
                        <div>Restricted</div>
                    </Button>
                } />
                <Popup content="Only authenticated users can see and book events" trigger={
                    <Button icon key={"authprovider-button-auth-" + key} active={this.state.calendarVisibility === CalendarVisibility.AuthToSee} className={"iconButton big"} onClick={() => this.setState({calendarVisibility: CalendarVisibility.AuthToSee})}>
                        <i className={"fas fa-user-lock fa-2x"}></i>
                        <div>Private</div>
                    </Button>
                } />
            </span>
        </div>;
    }

    private onSubmit = (): void => {
        const name: any = this.nameRef.current && this.nameRef.current.value;
        const description: any = this.descriptionRef.current && this.descriptionRef.current.value;
        const authConfigSchedule: any = this.authConfigScheduleRef.current && this.authConfigScheduleRef.current.value;
        const authBypassStub: string | null = this.authBypassStubRef.current && this.authBypassStubRef.current.value;
        const authConfigTicketing: any = this.authConfigTicketingRef.current && this.authConfigTicketingRef.current.value;
        const paymentProcessorToken: any = this.paymentProcessorTokenRef.current && this.paymentProcessorTokenRef.current.value;
        const calendar: Calendar = this.props.calendar ? this.props.calendar : {} as Calendar;
        const timezone: string = this.state.timezone ? this.state.timezone : this.originalTimezone;
        const submissionValidationEndpoint: any = this.submissionValidationEndpointRef.current && this.submissionValidationEndpointRef.current.value;
        const resources: PrivateCalendarResource[] = this.resourceMapping.map((idx) => this.resources[idx]);
        calendar.name = name;
        calendar.description = description;
        calendar.timezone = timezone;
        calendar.isReservable = this.state.isReservable;
        calendar.eventVisibility = this.state.eventVisibility;
        calendar.authProvider = this.state.authenticationProvider;
        calendar.authConfig = this.state.isReservable === CalendarReservationType.Reservable ? authConfigTicketing : authConfigSchedule;
        calendar.authCustomEndpoint = this.state.authCustomEndpoint;
        calendar.authBypass = this.state.authBypass;
        calendar.authBypassStub = authBypassStub ? authBypassStub : "";
        calendar.calendarVisibility = this.state.calendarVisibility;
        calendar.managedBy = this.state.managementMode;
        calendar.eventMarketingTools = this.state.eventMarketingToolsEnabled;
        calendar.paymentProcessor = this.state.paymentProcessor;
        calendar.paymentProcessorToken = paymentProcessorToken;
        calendar.submissionValidation = {
            validationEndpoint: submissionValidationEndpoint
        } as CustomSubmitValidator;
        calendar.resourceFieldProperties = this.toCalendarResourceFieldProperty(this.state.resourceFieldProperties);
        calendar.resources = resources;
        calendar.translations = this.state.translations;
        calendar.emailTemplates = this.state.emailTemplates;
        if (this.calendarInstances) calendar.instances = this.calendarInstances;
        console.log(calendar);
        this.props.onSubmit(calendar);
    }

    private getResourceFieldEnabledMap = (propertyMap: {[fieldName: string]: CalendarResourceFieldProperty}): {[fieldName: string]: boolean} => {
        const enabledMap: {[fieldName: string]: boolean} = {};
        if (!propertyMap) return enabledMap;
        Object.keys(propertyMap).forEach((fieldName) => {
            enabledMap[fieldName] = this.getResourceFieldEnabled(propertyMap[fieldName]);
        });
        return enabledMap;
    }

    private getResourceFieldEnabled = (property: CalendarResourceFieldProperty): boolean => {
        return property.enabled;
    }

    private toCalendarResourceFieldProperty = (enabledMap: {[fieldName: string]: boolean}): {[fieldName: string]: CalendarResourceFieldProperty} => {
        const propertyMap: {[fieldName: string]: CalendarResourceFieldProperty} = {};
        if (!enabledMap) return propertyMap;
        Object.keys(enabledMap).forEach((fieldName) => {
            propertyMap[fieldName] = {
                enabled: enabledMap[fieldName]
            } as CalendarResourceFieldProperty;
        });
        return propertyMap;
    }

    private isResourceFieldEnabled = (fieldName: string): boolean => {
        if (!this.state.resourceFieldProperties) {
            return false;
        } else if (!this.state.resourceFieldProperties[fieldName]) {
            return false;
        } else {
            return this.state.resourceFieldProperties[fieldName];
        }
    }

    private setResourceEnabledField = (fieldName: string, value: boolean) => {
        let resourceFieldProperties: {[fieldName: string]: boolean} = {};
        if (this.state.resourceFieldProperties) resourceFieldProperties = this.state.resourceFieldProperties;
        resourceFieldProperties[fieldName] = value;
        this.setState({resourceFieldProperties: resourceFieldProperties});
        this.onEditing();
    }

    private calendarSelectionChanged = (componentId: string, selectedInstance: CalendarInstance) => {
        if (selectedInstance) {
            this.calendarInstances = [];
            this.calendarInstances.push(selectedInstance);
        }
        this.onEditing();
    }

    private onEditing = () => {
        if (!this.descriptionRef.current || !this.nameRef.current || !this.authConfigScheduleRef.current || !this.authConfigTicketingRef.current || !this.paymentProcessorTokenRef.current || !this.submissionValidationEndpointRef.current) {
            return;
        }
        const instanceName: string = this.nameRef.current.value;
        const instanceDescription: string = this.descriptionRef.current.value;
        const instanceIsReservable: string = this.state.isReservable;
        const instanceAuthProvider: AuthProvider = this.state.authenticationProvider;
        const instanceAuthConfig: string = instanceIsReservable === CalendarReservationType.Reservable ? this.authConfigTicketingRef.current.value : this.authConfigScheduleRef.current.value;
        const instanceAuthBypassStub: string = this.authBypassStubRef.current ? this.authBypassStubRef.current.value : this.originalAuthBypassStub;
        const instanceSubmissionValidationEndpoint: string = this.submissionValidationEndpointRef.current.value;
        const instancePaymentProcessorToken: string = this.paymentProcessorTokenRef.current.value;
        // todo - loop through all resources to see if they have changed.
        if (this.originalName === instanceName
            && this.originalDescription === instanceDescription
            && this.originalTimezone === this.state.timezone
            && this.originalIsReservable === instanceIsReservable
            && this.originalEventVisibility === this.state.eventVisibility
            && this.originalCalendarVisibility === this.state.calendarVisibility
            && this.originalAuthProvider === instanceAuthProvider
            && this.originalAuthBypass === this.state.authBypass
            && this.originalAuthBypassStub === instanceAuthBypassStub
            && this.originalManagementMode === this.state.managementMode
            && this.originalEventMarketingTools === this.state.eventMarketingToolsEnabled
            && this.originalPaymentProcessor === this.state.paymentProcessor
            && this.originalPaymentProcessorToken === instancePaymentProcessorToken
            && this.originalSubmissionValidationEndpoint === instanceSubmissionValidationEndpoint
            && this.areTwoComponentTranslationsEqual(this.originalTranslations, this.state.translations)
            && this.areTwoEmailTemplateMapsEqual(this.originalEmailTemplates, this.state.emailTemplates)
            && this.areTwoMapsEqual(this.originalResourceFieldEnabledProperties, this.state.resourceFieldProperties)
            && this.originalAuthCustomEndpoint === this.state.authCustomEndpoint
            && this.originalAuthConfig === instanceAuthConfig) {
            this.setState({
                editing: false
            });
        } else {
            this.setState({
                editing: true
            });
        }
    }

    private areTwoEmailTemplateMapsEqual = (t1: {[id: string]: EmailTemplate}, t2: {[id: string]: EmailTemplate}) => {
        if (!t1 && !t2) return true;
        if (!t1) return false;
        if (!t2) return false;
        const t1Keys = Object.keys(t1);
        const t2Keys = Object.keys(t2);
        if (t1Keys.length !== t2Keys.length) return false;
        for (let i = 0; i < t1Keys.length; i++) {
            const key = t1Keys[i];
            if (!t2[key]) return false;
            if (!this.areTwoEmailTemplatesEqual(t1[key], t2[key])) return false;
        }
        return true;
    };

    private areTwoEmailTemplatesEqual = (email1: EmailTemplate, email2: EmailTemplate) => {
        if (email1.name !== email2.name) return false;
        if (email1.description !== email2.description) return false;
        if (email1.subject !== email2.subject) return false;
        if (email1.content !== email2.content) return false;
        return true;
    }

    private areTwoComponentTranslationsEqual = (trans1: ComponentTranslation, trans2: ComponentTranslation) => {
        if (!trans1 && !trans2) return true;
        if (!trans1) return false;
        if (!trans2) return false;
        if (trans1.defaultLocale !== trans2.defaultLocale) return false;
        if (trans1.descriptions !== trans2.descriptions) return false;
        if (!this.areTwoArraysEqual(trans1.supportedLocales, trans2.supportedLocales)) return false;
        if (!this.areTwoTranslationsMapsEqual(trans1.clientTranslations, trans2.clientTranslations)) return false;
        if (!this.areTwoTranslationsMapsEqual(trans1.serverTranslations, trans2.serverTranslations)) return false;
        return true;
    }

    private areTwoArraysEqual = (arr1: string[], arr2: string[]) => {
        if (!arr1 && !arr2) return true;
        if (!arr1) return false;
        if (!arr2) return false;
        if (arr1.length !== arr2.length) return false;
        const arr1Map: {[elem: string]: boolean} = {};
        arr1.forEach((elem) => arr1Map[elem] = true);
        let arrAreEqual: boolean = true;
        arr2.forEach((elem) => {
            if (!arr1Map[elem]) arrAreEqual = false;
        });
        return arrAreEqual;
    }

    private areTwoTranslationsMapsEqual = (trans1: {[locale: string]: Translation}, trans2: {[locale: string]: Translation}) => {
        if (!trans1 && !trans2) return true;
        if (!trans1) return false;
        if (!trans2) return false;
        const trans1Keys = Object.keys(trans1);
        const trans2Keys = Object.keys(trans2);
        if (trans1Keys.length !== trans2Keys.length) {
            return false;
        }
        for (const key1 of trans1Keys) {
            if (this.areTwoTranslationsEqual(trans1[key1], trans2[key1])) return false;
        }
        return true;
    }

    private areTwoTranslationsEqual = (trans1: Translation | undefined, trans2: Translation | undefined) => {
        if (trans1 === undefined && trans2 === undefined) return true;
        if (trans1 === undefined && trans2 !== undefined) return false;
        if (trans1 !== undefined && trans2 === undefined) return false;
        const t1: Translation = trans1!;
        const t2: Translation = trans2!;
        if (t1.locale !== t2.locale) return false;
        const t1Keys = Object.keys(t1);
        const t2Keys = Object.keys(t2);
        if (t1Keys.length !== t2Keys.length) return false;
        for (const key1 of t1Keys) {
            if (t1.messages[key1] !== t2.messages[key1]) return false;
        }
        return true;
    }

    private areTwoMapsEqual = (map1: {[fieldName: string]: boolean}, map2: {[fieldName: string]: boolean}) => {
        const map1Keys = Object.keys(map1);
        const map2Keys = Object.keys(map2);
        if (map1Keys.length !== map2Keys.length) {
            return false;
        }
        for (const key1 of map1Keys) {
            if (map1[key1] !== map2[key1]) return false;
        }
        return true;
    }

    private clearEditing = () => {
        if (this.nameRef.current) {
            this.nameRef.current.value = this.originalName;
        }
        if (this.descriptionRef.current) {
            this.descriptionRef.current.value = this.originalDescription;
        }
        if (this.authConfigScheduleRef.current) {
            this.authConfigScheduleRef.current.value = this.originalAuthConfig;
        }
        if (this.authConfigTicketingRef.current) {
            this.authConfigTicketingRef.current.value = this.originalAuthConfig;
        }
        if (this.paymentProcessorTokenRef.current) {
            this.paymentProcessorTokenRef.current.value = this.originalPaymentProcessorToken;
        }
        if (this.authBypassStubRef.current) {
            this.authBypassStubRef.current.value = this.originalAuthBypassStub;
        }
        if (this.submissionValidationEndpointRef.current) {
            this.submissionValidationEndpointRef.current.value = this.originalSubmissionValidationEndpoint;
        }
        // todo --> reset the mapping index to the original size, and issue all related editors to clear editing
        this.resources = this.originalResources;
        this.resourceMapping = this.originalResourceMapping;
        this.setState({
            editing: false,
            openClearEditWarning: false,
            timezone: this.originalTimezone,
            isReservable: this.originalIsReservable,
            calendarVisibility: this.originalCalendarVisibility,
            authenticationProvider: this.originalAuthProvider,
            authBypass: this.originalAuthBypass,
            managementMode: this.originalManagementMode,
            eventMarketingToolsEnabled: this.originalEventMarketingTools,
            paymentProcessor: this.originalPaymentProcessor,
            eventVisibility: this.originalEventVisibility,
            translations: this.originalTranslations,
            emailTemplates: this.originalEmailTemplates,
            authCustomEndpoint: this.originalAuthCustomEndpoint,
            resourceFieldProperties: this.originalResourceFieldEnabledProperties,
        });
    }

    private renderClearEditWarningModal = (): React.ReactElement<any> | undefined => {
        return <WarningModal
            descriptionIcon="close" open={this.state.openClearEditWarning}
            descriptionText={this.getString({id: "page.calendar.clear_edit"})}
            warningText={this.getString({id: "page.calendar.clear_edit_confirmation"})}
            onConfirm={this.clearEditing}
            onCancel={ () => {this.setState({openClearEditWarning: false}); }}/>;
    }
}

export default connectAllProps(CalendarEditor);