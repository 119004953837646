import ActionCreator from "../models/client/ActionCreator";
import userActionCreator from "./user";
import articleActionCreator from "./article";
import blockActionCreator from "./block";
import projectActionCreator from "./project";
import commonActionCreator from "./common";
import commentActionCreator from "./comment";
import notificationActionCreator from "./notification";
import tableViewActionCreator from "./table_view";
import uploaderActionCreator from "./uploader";
import threadActionCreator from "./thread";
import calendarActionCreator from "./calendar";

const actions: ActionCreator = {
    ...userActionCreator,
    ...articleActionCreator,
    ...blockActionCreator,
    ...calendarActionCreator,
    ...commentActionCreator,
    ...commonActionCreator,
    ...projectActionCreator,
    ...notificationActionCreator,
    ...tableViewActionCreator,
    ...threadActionCreator,
    ...uploaderActionCreator
};

export default actions;