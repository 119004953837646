import { combineReducers } from "redux";
import userState from "./user";
import articleState from "./article";
import blockState from "./block";
import calendarState from "./calendar";
import blockViewState from "./blockView";
import redirectTask from "./redirectTask";
import translations from "./translations";
import commentState from "./comment";
import userDictionary from "./userDictionary";
import fabActions from "./fabActions";
import threadState from "./thread";
import tableViewState from "./tableView";
import uploaderState from "./uploader";
import projectState from "./project";

const reducer = combineReducers({
    userState,
    projectState,
    articleState,
    calendarState,
    blockState,
    blockViewState,
    threadState,
    redirectTask,
    translations,
    commentState,
    userDictionary,
    tableViewState,
    uploaderState,
    fabActions
});

export default reducer;