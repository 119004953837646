import React from "react";
import { Header, Icon, Grid, Button, Container, Label, Table, Tab } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ComponentProps } from "../../../shared/ComponentProps";
import User from "../../../models/User";
import connectAllProps from "../../../shared/connect";

interface States {}
class Uploader extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="react"></Icon>
                            </div>
                            <span className="punchLine">Serverless uploader component</span>
                            <Header.Subheader className="punchLineSubHeader">
                                AirJam's uploader component lets you add upload features to your projects with just a few lines of code, so your users can start uploading files in minutes.
                            </Header.Subheader>
                            <Link to={user ? "/uploaders/" : "/login/"}>
                                <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                    <Button.Content visible>Go to console</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="terminal" />
                                    </Button.Content>
                                </Button>
                            </Link>
                            <Link to={user ? "/uploaders/" : "/join/"}>
                                <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                    <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </Link>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12} textAlign="left">
                        <Header as="h3" id="intro">AirJam Uploader Component</Header>
                        Serverless upload component for React. Authenticate using <a href="https://clerk.com/">Clerk</a> or <a href="https://firebase.google.com/docs/auth">Firebase Authentication</a>, or you can bypass authentication altogether.
                        <Header as="h3" id="setup">Setup</Header>
                        <Tab panes={[
                            {menuItem: "npm", render: () => <Tab.Pane>
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "npm install @airjam/react-uploader@latest" }
                                </SyntaxHighlighter>
                            </Tab.Pane>},
                            {menuItem: "yarn", render: () => <Tab.Pane>
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { "yarn add @airjam/react-uploader@latest" }
                                </SyntaxHighlighter>
                            </Tab.Pane>}
                        ]} menu={{ inverted: false, attached: true, tabular: true }} />
                        <Header as="h3" id="usage">Usage</Header>
                        First, <Link to="/join">sign up</Link>, go to <Link to={user ? "/uploaders/" : "/login/"}>uploader console</Link>, and create a new upload bucket.<br />
                        Get upload bucket ID and paste the code below with your bucket ID to your code.<br />
                        <SyntaxHighlighter language="javascript" style={vs2015}>
                            {  "import { Uploader } from '@airjam/react-uploader';\n...\n" +
                            '<Uploader id="<your-bucket-id>" uploaderName={username} />' }
                        </SyntaxHighlighter>
                        And, that's it! Enjoy!
                        <Header as="h3" id="props">Props and Callbacks</Header>
                        <ul>
                            <li><code>id</code> - <b>(REQUIRED)</b> bucket id of the uploader component.</li>
                            <li><code>uploaderName</code> - (Optional) Custom name of the user uploading the data object. This name is used as the name for the folder that stores the uploaded data.</li>
                            <li><code>authToken</code> - (Optional) User-level authentication token to pass to authentication service. This is a required field if you are using an authentication provider (such as Clerk or Firebase Authentication) </li>
                            <li>
                                <code>onRemove</code> - This is an event that gets fired when a user removes a file.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { '<Uploader id="<your-bucket-id>" onRemove={removed} />\n...\n function removed(removed) {\n  console.log("File(s) are removed");\n  removed.forEach(file => {\n    console.log("File unique id: " + file.id);\n    console.log("Publicly accessible url for the file: " + file.url);\n  });\n}\n' }
                                </SyntaxHighlighter>
                            </li>
                            <li>
                                <code>onAdd</code> - This is an event that gets fired when a user adds a file.
                                <SyntaxHighlighter language="javascript" style={vs2015}>
                                    { '<Uploader id="<your-bucket-id>" onAdd={added} />\n...\n function added(added) {\n  console.log("File(s) are added");\n  added.forEach(file => {\n    console.log("File unique id: " + file.id);\n    console.log("Publicly accessible url for the file: " + file.url);\n  });\n}\n' }
                                </SyntaxHighlighter>
                            </li>
                        </ul>
                        <Header as="h3" id="usage">Tracking uploaded files</Header>
                        AirJam's uploader component tracks uploaded files with an &lt;input&gt; element with ID being the specified bucket ID. If you are using &lt;form&gt; element, wrap &lt;form&gt; tag around the &lt;uploader&gt; tag and your form will be auto populated with the uploaded files and their filenames.
                        For more flexibility, use the onAdd and onRemove callbacks to track the uploaded files manually.
                        <Header as="h3" id="configuration">Configuration</Header>
                        Use the <Link to="/uploaders">uploader console</Link> to configure your uploader component, and your changes will be reflected immediately to your users upon a reload. You can use the console to adjust settings such as uploaded file count and size, UI styles, etc.
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_privacy.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            Serverless Authentication
                            <Header.Subheader className="subheader_more_margin_top">
                                Use your own authentication services, such as Clerk or Firebase Authentication, to control access to your uploader component. AirJam proxies to your authentication service so you can control access to your uploader components.
                            </Header.Subheader>
                            <br /><br />
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="middle">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Free to get started
                            <Header.Subheader className="subheader_more_margin_top">
                                Credit card is not required to get started with AirJam. Each account comes with 500 MB of data storage per month FREE.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_credit_card.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h2" textAlign="center">
                            <FormattedMessage id="page.view.table.pricing" /><sup>*</sup>
                            <Header.Subheader className="subheader_more_margin_top">
                                <FormattedMessage id="page.view.table.pricing_sub" />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={12}>
                        <Table celled definition>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>
                                        <Label ribbon color="yellow">No Credit Card Required!</Label>
                                        0 - 500 MB</Table.HeaderCell>
                                    <Table.HeaderCell>501 MB - 2 GB</Table.HeaderCell>
                                    <Table.HeaderCell>2 GB - 10 GB</Table.HeaderCell>
                                    <Table.HeaderCell>10 GB+</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Data Storage Fees<sup>**</sup></Table.Cell>
                                    <Table.Cell>
                                        FREE
                                    </Table.Cell>
                                    <Table.Cell>$0.040 USD<br />
                                        (per GB per month)</Table.Cell>
                                    <Table.Cell>$0.035 USD<br />
                                        (per GB per month)</Table.Cell>
                                    <Table.Cell>$0.025 USD<br />
                                        (per GB per month)</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Network Charges<sup>#</sup></Table.Cell>
                                    <Table.Cell>
                                        FREE
                                    </Table.Cell>
                                    <Table.Cell>FREE</Table.Cell>
                                    <Table.Cell>$0.03 USD / GB</Table.Cell>
                                    <Table.Cell>$0.02 USD / GB</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="5">
                                        <small><sup>*</sup> The charges are calculated based on usages across all upload buckets for each account for each monthly billing cycle. Prices are subjected to change. Please note that additional services may incur separate charges.</small>
                                        <br />
                                        <small><sup>**</sup> Storage fees are determined based on the duration for which your data is stored in upload buckets during the specified billing month.</small>
                                        <br />
                                        <small><sup>#</sup> Network charges encompass both the data transmitted to AirJam for storing data and the data retrieved from AirJam upload buckets.</small>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 200 }}>
                    <Grid.Column width={16} textAlign="center" verticalAlign="bottom">
                        <Link to={user ? "/uploaders/" : "/join/"}>
                            <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                <Button.Content hidden>
                                    <Icon name="arrow right" />
                                </Button.Content>
                            </Button>
                        </Link>
                    </Grid.Column>
                </Grid.Row>

            </Grid>


        </Container>;
    }
}

export default connectAllProps(Uploader);