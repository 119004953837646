import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { ComponentProps } from "../../../shared/ComponentProps";
import { FormattedMessage } from "react-intl";

const airjamScopes = "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.readonly.metadata https://www.googleapis.com/auth/calendar";
// const airjamScopes = "";

interface Props extends ComponentProps {
    googleLoginHook: any;
}

function withGoogleHook(Comp: any) {
    return function WrappedComponent(props: ComponentProps) {
        const googleLogin = useGoogleLogin({
            scope: airjamScopes,
            flow: "auth-code",
            onSuccess: tokenResponse => {
                props.actions.googleLogin(tokenResponse.code);
            },
            onError: tokenResponse => console.log(tokenResponse),
        });
        return <Comp {...props} googleLoginHook={googleLogin} />;
    };
}

class GoogleLogIn extends React.Component<Props, { googleLoginHook: any }> {
    googleLoginHook: any;

    constructor(props: any) {
        super(props);
        this.googleLoginHook = undefined;
        this.state = { googleLoginHook: "" };
    }

    render(): any {
        return (<div>
            <button type="button" className="google-auth" onClick={() => this.props.googleLoginHook()}>
                <FormattedMessage id={"component.button.login.with.google"} />
            </button>
        </div>);
    }
}

export default withGoogleHook(GoogleLogIn);