import React, { Fragment, RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import { Button, Dropdown, Form, Grid, Header, Icon, Image, Modal, Radio, Step } from "semantic-ui-react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import { refToTableView, TableView, TableViewRef, vendTableViewRef } from "../../../models/TableView";
import { PrimitiveType } from "intl-messageformat";
import GoogleLogin from "../auth/GoogleLogin";
import GoogleSheetDocumentSelector from "../components/GoogleSheetDocumentSelector";
import GoogleSheetSheetSelector from "../components/GoogleSheetSheetSelector";
import GoogleSheetSheetFieldConfiguration from "../components/GoogleSheetSheetFieldConfiguration";
import Project from "../../../models/Project";
import paginationStyles from "../components/PaginationStyles";
import sortByOptions from "../components/SortByOptions";
import ViewTypeSelect from "../components/ViewTypeSelect";
import TemplateSelect from "../components/TemplateSelect";
import StyleSelect from "../components/StyleSelect";
import TemplateFieldConfiguration from "../components/TemplateFieldConfiguration";
import { DataSourceField, TableViewViewType } from "@airjam/types";

enum WizardStep {
    WELCOME = "welcome",
    SELECT_COMPONENTS = "select_components",
    CUSTOMIZE = "customize",
    FINISH = "finish"
}

enum CustomizeStep {
    CONFIG = "config",
    UI = "ui"
}

const wizardFlow: WizardStep[] = [WizardStep.WELCOME, WizardStep.SELECT_COMPONENTS, WizardStep.CUSTOMIZE, WizardStep.FINISH];
const customizeStepFlow: CustomizeStep[] = [CustomizeStep.CONFIG, CustomizeStep.UI];

interface Props extends ComponentProps {
    visible: boolean;
    closeModal: () => void;
}

interface TableViewRefWizard extends TableViewRef {
    currentStep: CustomizeStep;
}


interface States {
    currentStep: WizardStep;
    componentCount: number;
    currentComponentIdx: number;
    dirty: boolean;
}

class CreateProjectWizard extends React.Component<Props, States> {
    private titleRef: RefObject<HTMLInputElement>;
    private tableViewComponentsRef: TableViewRefWizard[];
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.titleRef = React.createRef();
        this.tableViewComponentsRef = [];
        this.getString = this.props.intl.formatMessage;
        this.state = {
            currentStep: WizardStep.WELCOME,
            currentComponentIdx: 0,
            componentCount: 0,
            dirty: false,
        };
    }
    render(): React.ReactElement<any> {
        if (this.props.state.userState.currentUser) {
            return (
                <Modal
                    dimmer="inverted"
                    open={this.props.visible}>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid>
                                <Grid.Row style={{ minHeight: 300 }} columns={2}>
                                    <Grid.Column width={5}>
                                        { this.showNavbar() }
                                    </Grid.Column>
                                    { this.welcomeStep() }
                                    { this.selectComponentsStep() }
                                    { this.configureComponentStep() }
                                    { this.configureLookAndFeelStep() }
                                    { this.showFinishStep() }
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.goBackToPreviousStep()} disabled={this.isFirstStep()}>
                            <FormattedMessage id="component.button.previous" />
                        </Button>
                        <Button onClick={() => this.moveToNextStep()} disabled={this.isLastStep() || this.isCurrentStepIncomplete()}>
                            <FormattedMessage id="component.button.next" />
                        </Button>
                        <Button onClick={() => this.confirmAndCloseModal()}>
                            <FormattedMessage id="component.button.close" />
                        </Button>
                    </Modal.Actions>
                </Modal>
            );
        } else {
            return <i></i>;
        }
    }

    private showNavbar = (): React.ReactElement<any> => {
        return <Step.Group vertical>
            <Step active={this.isCurrentStep(WizardStep.WELCOME)} onClick={() => this.changeStep(WizardStep.WELCOME)} disabled={!this.canNavigateToStep(WizardStep.WELCOME)}>
                <Icon name="compass outline" />
                <Step.Content>
                    <Step.Title>
                        <FormattedMessage id="page.project.wizard.navigation.welcome" />
                    </Step.Title>
                </Step.Content>
            </Step>

            <Step active={this.isCurrentStep(WizardStep.SELECT_COMPONENTS)} onClick={() => this.changeStep(WizardStep.SELECT_COMPONENTS)} disabled={!this.canNavigateToStep(WizardStep.SELECT_COMPONENTS)}>
                <Icon name="cubes" />
                <Step.Content>
                    <Step.Title>
                        <FormattedMessage id="page.project.wizard.navigation.select_components" />
                    </Step.Title>
                </Step.Content>
            </Step>

            <Step active={this.isCurrentStep(WizardStep.CUSTOMIZE)} onClick={() => this.changeStep(WizardStep.CUSTOMIZE)} disabled={!this.canNavigateToStep(WizardStep.CUSTOMIZE)}>
                <Icon name="cogs" />
                <Step.Content>
                    <Step.Title>
                        <FormattedMessage id="page.project.wizard.navigation.customize_components" />
                    </Step.Title>
                    <Step.Description>
                        {
                            Array.apply(0, Array(this.state.componentCount)).map((x, i) => {
                                if ((i < 0) || (!this.tableViewComponentsRef[i])) {
                                    return <span key={i}></span>;
                                }
                                return <div key={i}>
                                    <span onClick={() => this.changeComponentIndex(i)}>
                                        {this.refValueOrDefault(this.tableViewComponentsRef[i].title, this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: i + 1 }))}
                                    </span>
                                    <br />
                                    <span onClick={() => this.changeComponentStep(i, CustomizeStep.CONFIG)}>
                                        &nbsp;&nbsp;&nbsp;&nbsp; ⊙ <FormattedMessage id="page.project.wizard.navigation.customize_components.configure" />
                                    </span>
                                    <br />
                                    <span onClick={() => this.changeComponentStep(i, CustomizeStep.UI)}>
                                        &nbsp;&nbsp;&nbsp;&nbsp; ⊙ <FormattedMessage id="page.project.wizard.navigation.customize_components.ui" />
                                    </span>
                                    <br />
                                </div>;
                            })
                        }
                    </Step.Description>
                </Step.Content>
            </Step>

            <Step active={this.isCurrentStep(WizardStep.FINISH)} onClick={() => this.changeStep(WizardStep.FINISH)} disabled={!this.canNavigateToStep(WizardStep.FINISH)}>
                <Icon name="flag checkered" />
                <Step.Content>
                    <Step.Title>
                        <FormattedMessage id="page.project.wizard.navigation.finish" />
                    </Step.Title>
                </Step.Content>
            </Step>
        </Step.Group>;
    }

    private canNavigateToStep = (step: WizardStep): boolean => {
        switch (step) {
            case WizardStep.WELCOME:
                return true;
            case WizardStep.SELECT_COMPONENTS:
                return this.props.state.userState.currentUser?.oauth.google.email;
            case WizardStep.CUSTOMIZE:
                return this.tableViewComponentsRef.length > 0;
            case WizardStep.FINISH:
                return this.canNavigateToStep(WizardStep.CUSTOMIZE) && this.state.dirty;
        }
    }
    private welcomeStep = (): React.ReactElement<any> => {
        if (!this.props.state.userState.currentUser?.oauth.google.email) {
            return <Grid.Column width={11} className={"wizardContent " + this.hideClassIfNotCurrentStep(WizardStep.WELCOME)}>
                <Header></Header>
                <Header size="large"><FormattedMessage id="page.project.wizard.welcome.title" /></Header>
                <span><FormattedMessage id="page.project.wizard.welcome.title.link_google" /></span>
                <p></p>
                <GoogleLogin {...this.props} />
            </Grid.Column>;
        } else {
            return <Grid.Column width={11} className={"wizardContent " + this.hideClassIfNotCurrentStep(WizardStep.WELCOME)}>
                <Header></Header>
                <Header size="large"><FormattedMessage id="page.project.wizard.welcome.title" /></Header>
                <span><FormattedMessage id="page.project.wizard.welcome.description" /></span>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <Form>
                    <input autoFocus={true} defaultValue={this.getString({ id: "page.project.wizard.welcome.title.default" })} onChange={this.onEditing} ref={this.titleRef} />
                </Form>
            </Grid.Column>;
        }
    }

    private selectComponentsStep = (): React.ReactElement<any> => {
        return <Grid.Column width={11} className={"wizardContent " + this.hideClassIfNotCurrentStep(WizardStep.SELECT_COMPONENTS)}>
            <Header size="large"><FormattedMessage id="page.project.wizard.component.title" /></Header>
            <span><FormattedMessage id="page.project.wizard.component.description" /></span>
                <p>&nbsp;</p>
                <Form>
                    <Form.Field>
                        <Radio
                            label="One"
                            name="componentCount"
                            value="1"
                            checked={this.state.componentCount === 1}
                            onChange={() => this.changeComponentCount(1)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="Two"
                            name="componentCount"
                            value="2"
                            checked={this.state.componentCount === 2}
                            onChange={() => this.changeComponentCount(2)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="Three"
                            name="componentCount"
                            value="3"
                            checked={this.state.componentCount === 3}
                            onChange={() => this.changeComponentCount(3)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label="Four"
                            name="componentCount"
                            value="4"
                            checked={this.state.componentCount === 4}
                            onChange={() => this.changeComponentCount(4)}
                        />
                    </Form.Field>
                </Form>
        </Grid.Column>;
    }

    private configureComponentStep = (): React.ReactElement<any> => {
        return <div> {
            this.tableViewComponentsRef.map((componentRef, index) => {
                if (index >= this.state.componentCount) return <i key={index}></i>;
                return <Grid.Column width={11} key={index} className={"wizardContent " + this.hideClassIfNotCurrentComponent(index, CustomizeStep.CONFIG)}>
                <Header size="large">{this.getString({ id: "page.project.wizard.configure.title" }, { title: this.refValueOrDefault(componentRef.title, this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: index + 1 })) })}</Header>
                <span>{this.getString({ id: "page.project.wizard.configure.description" }, { title: this.refValueOrDefault(componentRef.title, this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: index + 1 })) })}</span>
                <p></p>
                <Form>
                    <label>
                        <FormattedMessage id="data.source.spreadsheet.component.title" />
                    </label>
                        <input autoFocus={true} defaultValue={ this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: (index + 1)}) } onChange={this.onEditing} ref={componentRef.title} />
                    <p></p>
                    <label>
                        <FormattedMessage id="data.source.spreadsheet.select" />
                    </label>
                    <GoogleSheetDocumentSelector {...this.props} onChange={this.sheetSelectionChanged} componentId={componentRef.tableViewId} initialKey={componentRef.currentSheetKey} key={"doc.selector." + index} />
                    <label className={this.hideClassIfEmpty(componentRef.currentSheetKey)}>
                        <FormattedMessage id="data.source.spreadsheet.select.sheet" />
                    </label>
                    <GoogleSheetSheetSelector {...this.props} componentId={componentRef.tableViewId} sheetId={componentRef.currentSheetKey} onChange={this.sheetSheetSelectionChanged} initialSheetSelection={componentRef.currentSheetSelection} key={"sheet.selector." + index} className={this.hideClassIfEmpty(componentRef.currentSheetKey)} />
                    <label className={this.hideClassIfEmpty(componentRef.currentSheetSelection)}>
                        <FormattedMessage id="data.source.spreadsheet.configure" />
                    </label>
                    <GoogleSheetSheetFieldConfiguration {...this.props} componentId={componentRef.tableViewId} sheetId={componentRef.currentSheetKey} onChange={this.fieldMappingChanged} sheetKey={componentRef.currentSheetSelection} key={"field.selector." + index} className={this.hideClassIfEmpty(componentRef.currentSheetSelection)} />
                </Form>
            </Grid.Column>;
            })
        }
        </div>;
    }

    private configureLookAndFeelStep = (): React.ReactElement<any> => {
        // 0. view type selector.
        // 1. load the list of compatible templates, show as select buttons
        // 2. load the list of compatible styles for the given template
        return <div> {
            this.tableViewComponentsRef.map((componentRef, index) => {
                if (index >= this.state.componentCount) return <i key={index}></i>;
                return <Grid.Column width={11} key={index} className={"wizardContent " + this.hideClassIfNotCurrentComponent(index, CustomizeStep.UI)}>
                        <Header size="large">{this.getString({ id: "page.project.wizard.look_and_feel.title" }, { title: this.refValueOrDefault(componentRef.title, this.getString({ id: "page.project.wizard.configure.title.default_name" }, { index: index + 1 })) })}</Header>
                        <span><FormattedMessage id="page.project.wizard.look_and_feel.description" /></span>

                        <p></p>
                        <label>
                            <FormattedMessage id="component.view.type.title" />
                        </label>
                        <ViewTypeSelect key={"view_type_" + index} componentId={componentRef.tableViewId} onChange={this.viewTypeSelectionChanged}></ViewTypeSelect>

                        <p></p>
                        <label className={componentRef.type ? "" : "hideImportant"}>
                            <FormattedMessage id="page.project.wizard.look_and_feel.theme" />
                        </label>
                        <TemplateSelect key={"template_select_" + index} componentId={componentRef.tableViewId} selectedViewType={componentRef.type} onChange={this.templateSelectionChanged}></TemplateSelect>

                        <p></p>
                        <label className={componentRef.type && componentRef.templateId ? "" : "hideImportant"}>
                            <FormattedMessage id="page.project.wizard.look_and_feel.style" />
                        </label>
                        <StyleSelect key={"style_select_" + index} componentId={componentRef.tableViewId} selectedTemplateId={componentRef.templateId} onChange={this.styleSelectionChanged}></StyleSelect>

                        <p></p>
                        <label className={componentRef.type && componentRef.templateId ? "" : "hideImportant"}>
                            <FormattedMessage id="page.project.wizard.look_and_feel.template_field_mapping" />
                        </label>
                        <TemplateFieldConfiguration key={"template_field_config_" + index} componentId={componentRef.tableViewId} selectedTemplateId={componentRef.templateId} fieldMapping={componentRef.fieldMappingResult} initialTemplateFieldMapping={componentRef.templateFieldMapping} onChange={this.templateMappingChanged}></TemplateFieldConfiguration>

                        <span className={(componentRef.type && this.paginationStyleApplicable(componentRef)) ? "" : "hideImportant"}>
                        <p></p>
                        <label>
                            <FormattedMessage id="page.project.wizard.look_and_feel.pagination" />
                        </label>
                        <Dropdown
                            placeholder={this.getString({ id: "component.table.view.pagination_style_select" })}
                            key={"pagination_" + index}
                            fluid
                            selection
                            onChange={(e: any, value: any) => {
                                componentRef.currentPaginationStyle = value.value;
                                this.onEditing();
                            }}
                            options={paginationStyles(this.props)}
                        />
                        </span>

                        <p></p>
                        <label>
                            <FormattedMessage id="page.project.wizard.look_and_feel.sort_by" />
                        </label>
                        <Dropdown
                            placeholder={this.getString({ id: "component.dropdown.sort_by_select" })}
                            key={"sort_by_" + index}
                            fluid
                            selection
                            onChange={(e: any, value: any) => {
                                componentRef.currentSortBy = value.value;
                                this.onEditing();
                            }}
                            options={sortByOptions(this.props)}
                        />


                </Grid.Column>;
            })
        }
        </div>;
    }

    private paginationStyleApplicable = (tableView: TableViewRef): boolean => {
        const viewType = TableViewViewType[tableView.type as keyof typeof TableViewViewType];
        switch (viewType) {
            case TableViewViewType.List:
            case TableViewViewType.Gallery:
                return true;
        }
        return false;
    }

    private showFinishStep = (): React.ReactElement<any> => {
        return <Grid.Column width={11} className={"wizardContent " + this.hideClassIfNotCurrentStep(WizardStep.FINISH)}>
                <Header></Header>
                <Header size="large">
                    <FormattedMessage id="page.project.wizard.finish.title" />
                </Header>
                <span>
                    <FormattedMessage id="page.project.wizard.finish.description" />
                </span>
                <p></p>
                <div>
                <Button size="large" color="red" animated onClick={() => this.createProject()}>
                    <Button.Content visible>Create Project</Button.Content>
                    <Button.Content hidden>
                        <Icon name="magic" />
                    </Button.Content>
                </Button>
                </div>
            </Grid.Column>;
    }

    private hideClassIfNotCurrentStep = (step: WizardStep): string => {
        if (step === this.state.currentStep) return "";
        return "hideImportant";
    }

    private hideClassIfNotCurrentComponent = (componentIndex: number, customizeStep: CustomizeStep): string => {
        if ((this.tableViewComponentsRef.length > 0) &&
            (this.state.currentStep === WizardStep.CUSTOMIZE) &&
            (componentIndex === this.state.currentComponentIdx) &&
            (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStep)) return "";
        return "hideImportant";
    }

    private hideClassIfEmpty = (componentValue: string): string => {
        if (!componentValue) return "hideImportant";
        return "";
    }

    private changeStep = (newStep: WizardStep) => {
        this.setState({
            currentStep: newStep,
            currentComponentIdx: 0
        });
    }

    private refValueOrDefault(ref: RefObject<HTMLInputElement>, defaultStr: string): string {
        if (ref && ref.current) return ref.current.value;
        return defaultStr;
    }

    private changeComponentIndex = (newIndex: number) => {
        this.setState({
            currentComponentIdx: newIndex
        });
        this.forceUpdate();
    }

    private changeComponentStep = (newIndex: number, newStep: CustomizeStep) => {
        this.setState({
            currentComponentIdx: newIndex
        });
        this.tableViewComponentsRef[newIndex].currentStep = newStep;
        this.forceUpdate();
    }

    private isCurrentStep = (step: WizardStep): boolean => {
        return this.state.currentStep === step;
    }

    private isCurrentStepIncomplete = (): boolean => {
        // there's only a single restriction atm.
        return ((this.state.currentStep === WizardStep.SELECT_COMPONENTS) && (!this.state.componentCount));
    }

    private sheetSelectionChanged = (componentId: string, newKey: string) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].currentSheetKey = newKey;
        this.tableViewComponentsRef[this.state.currentComponentIdx].currentSheetSelection = ""; // reset sheet selection when doc changes
        this.tableViewComponentsRef[this.state.currentComponentIdx].fieldMapping = React.createRef();
        this.onEditing();
    }

    private sheetSheetSelectionChanged = (componentId: string, newSheet: string) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].currentSheetSelection = newSheet;
        this.onEditing();
    }

    private fieldMappingChanged = (componentId: string, key: string, newMapping: {[id: string]: DataSourceField}, example: any) => {
        // todo(minjae): use the key to remove the dependency on current index state
        this.tableViewComponentsRef[this.state.currentComponentIdx].fieldMappingResult = newMapping;
        this.onEditing();
    }

    private viewTypeSelectionChanged = (componentId: string, newType: string) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].type = newType;
        this.onEditing();
    }

    private templateSelectionChanged = (componentId: string, newTemplate: string, newTemplateVersion: number) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].templateId = newTemplate;
        this.tableViewComponentsRef[this.state.currentComponentIdx].templateVersion = newTemplateVersion;
        this.onEditing();
    }

    private styleSelectionChanged = (componentId: string, newStyle: string, newVersion: number) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].styleId = newStyle;
        this.tableViewComponentsRef[this.state.currentComponentIdx].styleVersion = newVersion;
        this.onEditing();
    }

    private templateMappingChanged = (componentId: string, templateMapping: {[id: string]: string}) => {
        this.tableViewComponentsRef[this.state.currentComponentIdx].templateFieldMapping = templateMapping;
        this.onEditing();
    }

    private onEditing = () => {
        this.setState({
            dirty: true
        });
    }

    private changeComponentCount = (newCount: number): void => {
        // 1. if the count increased, add new elements
        // 2. if the count decreased, do nothing, just let the refs hanging
        if (this.tableViewComponentsRef.length < newCount) {
            for (let i = this.tableViewComponentsRef.length; i < newCount; i++) {
                this.tableViewComponentsRef.push(this.vendNewTableViewRef());
            }
        }
        this.setState({
            componentCount: newCount
        });
        this.forceUpdate();
    }

    private vendNewTableViewRef = (): TableViewRefWizard => {
        const newRef: TableViewRefWizard = vendTableViewRef() as TableViewRefWizard;
        newRef.currentStep = CustomizeStep.CONFIG;
        return newRef;
    }

    private moveToNextStep = () => {
        if (!this.state.currentStep) return;
        if (this.state.currentStep === WizardStep.CUSTOMIZE && !this.isLastCustomizeStep()) {
            // 1. if last step, move component idx
            // 2. else, move step
            if (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[customizeStepFlow.length - 1]) {
                this.tableViewComponentsRef[this.state.currentComponentIdx + 1].currentStep = customizeStepFlow[0];
                this.setState({
                    currentComponentIdx: this.state.currentComponentIdx + 1
                });
            } else {
                for (let j = 0; j < customizeStepFlow.length - 1; j++) {
                    if (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[j]) {
                        this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep = customizeStepFlow[j + 1];
                    }
                }
            }
        } else {
            for (let i = 0; i < wizardFlow.length - 1; i++) {
                if (this.state.currentStep === wizardFlow[i]) {
                    this.setState({
                        currentStep: wizardFlow[i + 1]
                    });
                }
            }
        }
        this.forceUpdate();
    }

    private goBackToPreviousStep = () => {
        if (!this.state.currentStep) return;
        // if customize step and not first step, then move within customize step. if not move step backward.
        // within customize step, if not first step, move step, if not, move component backward
        if (this.state.currentStep === WizardStep.CUSTOMIZE && !this.isFirstCustomizeStep()) {
            if (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[0]) {
                this.tableViewComponentsRef[this.state.currentComponentIdx - 1].currentStep = customizeStepFlow[customizeStepFlow.length - 1];
                this.setState({
                    currentComponentIdx: this.state.currentComponentIdx - 1
                });
            } else {
                for (let j = 1; j < customizeStepFlow.length; j++) {
                    if (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[j]) {
                        this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep = customizeStepFlow[j - 1];
                    }
                }
            }
        } else {
            for (let i = 1; i < wizardFlow.length; i++) {
                if (this.state.currentStep === wizardFlow[i]) {
                    this.setState({
                        currentStep: wizardFlow[i - 1]
                    });
                }
            }
        }
        this.forceUpdate();
    }

    private isFirstStep = (): boolean => {
        if (!this.state.currentStep) return false;
        return (this.state.currentStep === wizardFlow[0]);
    }
    private isLastStep = (): boolean => {
        if (!this.state.currentStep) return false;
        return (this.state.currentStep === wizardFlow[wizardFlow.length - 1]);
    }
    private isFirstCustomizeStep = (): boolean => {
        if (!this.state.currentStep) return false;
        return (
            (this.state.currentStep === WizardStep.CUSTOMIZE) &&
            (this.state.currentComponentIdx === 0) &&
            (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[0]));
    }
    private isLastCustomizeStep = (): boolean => {
        if (!this.state.currentStep) return false;
        return (
            (this.state.currentStep === WizardStep.CUSTOMIZE) &&
            (this.state.currentComponentIdx === this.state.componentCount - 1) &&
            (this.tableViewComponentsRef[this.state.currentComponentIdx].currentStep === customizeStepFlow[customizeStepFlow.length - 1]));
    }

    private confirmAndCloseModal = () => {
        if (this.state.dirty) {
            if (window.confirm(this.getString({ id: "page.project.wizard.close.confirm" }))) {
                this.props.closeModal();
            }
        } else {
            this.props.closeModal();
        }
    }
    private createProject = (): void => {
        if (this.props.state.userState.currentUser) {
            this.props.actions.addProject(this.convertToProject());
            this.props.closeModal();
        }
    }

    private convertToProject = (): Project => {
        return {
            title: this.titleRef.current?.value,
            componentCount: this.state.componentCount,
            tableViewComponents: this.convertToTableViewComponents()
        } as Project;
    }

    private convertToTableViewComponents = (): TableView[] => {
        const components: TableView[] = [];
        for (let i = 0; i < Math.min(this.tableViewComponentsRef.length, this.state.componentCount); i++) {
            const component = this.tableViewComponentsRef[i];
            components.push(refToTableView(component));
        }
        return components;
    }
}

export default connectAllProps(CreateProjectWizard);