import React from "react";
import connectAllProps from "../../../shared/connect";
import { MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import { PrimitiveType } from "intl-messageformat";
import fetch from "../../../shared/fetch";
import { EventReservation } from "@airjam/types";

interface Props extends ComponentProps {
}

interface States {
    loading: boolean;
    reservationDetail: EventReservation | undefined;
    deleted: boolean;
}

class CalendarReservationCancellation extends React.Component<Props, States> {
    private reservationId: string = "";
    private moderationKey: string = "";

    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    constructor(props: Props) {
        super(props);
        this.reservationId = this.props.match && this.props.match.params && this.props.match.params.reservationId;
        this.moderationKey = this.props.match && this.props.match.params && this.props.match.params.moderationKey;
        this.getString = this.props.intl.formatMessage;
        this.state = {
            loading: true,
            reservationDetail: undefined,
            deleted: false,
        };
    }
    componentDidMount() {
        this.getReservationDetail();
    }

    render(): React.ReactElement<any> {
        document.title = "Cancel Reservation";
        if (this.state.loading) return <div><div className="overlay"></div><div className="exclusive-dialog-container">Please wait while your reservation detail is being loaded...</div></div>;
        if (!this.state.reservationDetail) return <div><div className="overlay"></div><div className="exclusive-dialog-container">Sorry we are unable to retrieve your reservation details. Please try again later.</div></div>;
        document.title = this.state.reservationDetail.resourceName + " - Cancel Reservation";
        const startTime = new Date(this.state.reservationDetail.startTimeUtc);
        return <div>
            <div className="overlay"></div>
            <div className="exclusive-dialog-container">
                <h2>Cancel Reservation</h2>
                <div className="dialog-message">
                    {this.state.deleted ? "Your reservation is now deleted." : "Are you sure you want to cancel this reservation?"}
                </div>
                <div className="dialog-body">
                    <div><i className="fas fa-ticket-alt"></i> {this.state.reservationDetail.resourceName}</div>
                    {this.state.reservationDetail.location ? <div><i className="fas fa-map-marker-alt"></i> {this.state.reservationDetail.location}</div> : ""}
                    <div><i className="far fa-calendar"></i> {this.showTime(startTime)}</div>
                    <div><i className="fas fa-globe-americas"></i> {Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
                </div>
                <div className="dialog-action">
                    {this.state.deleted ? "" : <button onClick={() => this.deleteReservation()}>Confirm cancellation</button>}
                </div>
            </div>
        </div>;
    }

    private showTime = (timeToShow: Date) => {
        return timeToShow.toLocaleString("en-US", { weekday: "short", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", hour12: true });
    }

    private deleteReservation = (): void => {
        fetch(`/s/calendar/book?id=${this.reservationId}&key=${this.moderationKey}`, undefined, "DELETE")
        .then((cancelResult: any) => {
            if (cancelResult && cancelResult.success) {
                console.log("deleted");
                this.setState({ deleted: true});
            } else {
                console.log("unable to delete, show alert");
                alert("There was a problem cancelling your reservation, please try again later.");
            }
        });
    }

    private getReservationDetail = (): void => {
        fetch(`/s/calendar/reservation?id=${this.reservationId}&key=${this.moderationKey}`, undefined, "GET")
        .then((fetchedReservation: EventReservation) => {
            if (fetchedReservation.reservationId === this.reservationId) {
                this.setState({ reservationDetail: fetchedReservation, loading: false});
            } else {
                this.setState({ loading: false });
            }
        }).catch((err: any) => {
            console.log(err);
            this.setState({ loading: false });
        });
    }
}

export default connectAllProps(CalendarReservationCancellation);