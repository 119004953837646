import React from "react";
import { Header, Icon, Grid, Button, Container } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
interface Props {}

interface States {}
export default class Boards extends React.Component<Props, States> {
    render() {
        return <Container style={CONTAINER_STYLE}>
            <Grid style={{ backgroundImage: "url('/images/board_punch2.png')", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                <Grid.Row style={{ minHeight: 300 }}></Grid.Row>
                <Grid.Row>
                    <Grid.Column width={7}>
                        <Container textAlign="center">
                            <Header as="h2" textAlign="center" size="large" style={{ width: "100%" }}>
                                <FormattedMessage id="page.board.punch_line" />
                                <Header.Subheader>
                                    <FormattedMessage id="page.board.punch_line_sub" />
                                </Header.Subheader>
                                <Button animated="fade" style={{ marginTop: 15, marginBottom: 20 }} color="orange">
                                    <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="arrow right" />
                                    </Button.Content>
                                </Button>
                            </Header>
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        pic
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Container>
                <Grid>
                    <Grid.Row style={{ minHeight: 250 }}></Grid.Row>
                    <Grid.Row textAlign="left">
                        <Header as="h2" size="large" style={{ width: "100%" }}>
                            <FormattedMessage id="page.board.serverless" />
                        <Header.Subheader>
                            <FormattedMessage id="page.board.serverless_sub" />
                        </Header.Subheader>
                        </Header>
                    </Grid.Row>
                </Grid>
            </Container>
            <Container>
                <Grid>
                    <Grid.Row style={{ minHeight: 250 }}></Grid.Row>
                    <Grid.Row textAlign="right">
                        <Header as="h2" size="large" textAlign="right" style={{ width: "100%" }}>
                            <FormattedMessage id="page.board.free_to_begin" />
                            <Header.Subheader>
                                <FormattedMessage id="page.board.free_to_begin_sub" />
                            </Header.Subheader>
                        </Header>
                    </Grid.Row>
                </Grid>
            </Container>
        </Container>;
    }
}