import { Translation } from "@airjam/types";
import { ARTICLE_TITLE_MAX_LENGTH, ARTICLE_CONTENT_MIN_LENGTH, ARTICLE_CONTENT_MAX_LENGTH, THREAD_CONTENT_MAX_LENGTH, THREAD_TITLE_MAX_LENGTH, PASSWORD_MIN_LENGTH, UPLOADER_NAME_MIN_LENGTH } from "../constants";

const TRANSLATION: Translation = {
    locale: "en-US",
    messages: {
        // App basic info
        "app.name": "AirJam",
        "app.footer": "Copyright © 2023 Creative Construct, Inc. All Rights Reserved.",
        "app.connect_error": "Failed to fetch", // Do not change this phrase, it is used as keywords.
        "no": "No",
        "yes": "Yes",
        "javascript": "Javascript",
        "typescript": "Typescript",
        "react": "React.JS",
        "today": "Today",
        "week": "Week",
        "30d": "30 Days",

        // Pages.
        // pattern: page.<page_name>.<section>
        "page.home": "Home",
        "page.about": "About ",
        "page.privacy": "Privacy ",
        "page.terms": "Terms ",
        "page.about.introduction": "This project is intended to build a RESTful web app for all platforms in TypeScript. With this project you can build a web app including server, website, Android app and iOS app in one programming language.",
        "page.about.learn_more": "Learn More",
        "page.board": "Boards",
        "page.board.punch_line": "Build boards with just 3 lines of code.",
        "page.board.punch_line_sub": "Add content boards to your React service with just 3 lines of code. No servers!",
        "page.board.serverless": "Completely serverless",
        "page.board.serverless_sub": "AirJam boards are designed to operate with frontend code only. Saves hours of server development and maintenance cost",
        "page.board.free_to_begin": "No credit card is required to start.",
        "page.board.free_to_begin_sub": "AirJam provides transparent and easy to understand pricing. And you never need a credit card to begin!",
        "page.view.table.punch_line": "Sheet into an app page with 2 lines of code",
        "page.view.table.punch_line_sub": "AirJam's table view component lets you use spreadsheets or AirTable as the data source for your website with 2 lines of code. No backend code, period",
        "page.view.table.spreadsheet": "Manage directly from your spreadsheet",
        "page.view.table.spreadsheet_sub": "Use the table view component and bring your spreadsheets or AirTable data to your app or web service in a secure way. What's more, you manage your postings directly from your spreadsheet",
        "page.view.table.customizable": "You control the look and feel.",
        "page.view.table.customizable_sub": "UI flexibility is our thing! Use AirJam's dashboard to control the component's behavior and look and feel, all without having to change your code",
        "page.view.table.privacy": "Your privacy and security first",
        "page.view.table.privacy_sub": "AirJam proxies only the selected portion of your spreadsheet data to your users so your spreadsheet document is protected from public access. Also, AirJam does not store your spreadsheet data, so your data remains solely in your control",
        "page.view.table.no_payment": "No credit card required to begin",
        "page.view.table.no_payment_sub": "AirJam's components grow with your project. AirJam is FREE to begin, no credit card required.",
        "page.view.table.pricing": "Pricing",
        "page.view.table.pricing_sub": "Get started with AirJam for free. No credit card is required to get started, and you can cancel at anytime",
        "page.me": "Me",
        "page.me.login": "Sign in",
        "page.me.forget_password": "Forget password?",
        "page.me.sign_up": "Sign up",
        "page.me.profile": "Profile",
        "page.me.logout": "Log out",
        "page.me.preferences": "Preferences",
        "page.me.payment_methods": "Payment Methods",
        "page.me.payment_methods.description": "AirJam uses your payment methods to charge your account at the end of each billing cycle. Please note, AirJam will first use the primary payment method chosen to charge the outstanding balance first, then it will cycle through the remaining payment methods registered to the account until the charge is cleared.",
        "page.me.security": "Change Password",
        "page.me.change_password": "Change Password",
        "page.me.reset_password": "Forget Password",
        "page.me.reset_password_step_1": "Fill Your Account",
        "page.me.reset_password_step_2": "Verify Email",
        "page.me.reset_password_step_3": "Reset Password",
        "page.me.notifications": "Notifications",
        "page.threads": "Forum",
        "page.thread.add": "Add thread",
        "page.thread.empty": "No threads are added up to now.",
        "page.thread.placeholder": "Anything you would like to post...",
        "page.thread.removed": ">>> The original thread has been removed <<<",
        "page.thread.delete": "Delete Thread: {title}",
        "page.thread.delete_confirmation": "You cannot restore this thread after delete. Are you sure to delete?",
        "page.avatar.title": "Adjust Your Profile Image",
        "page.avatar.rotate": "Rotate",
        "page.avatar.zoom": "Zoom",
        "page.avatar.inquiry": "Is it okay to use this photo?",
        "page.consent.greeting": "Hi {email},",
        "page.consent.description": "{app_name} is requesting access to your account.",
        "page.consent.inquiry": "Do you approve?",
        "page.consent.OTP": "Please fill the code sent to your mailbox, it will expire in 10 minutes.",
        "page.consent.OTP_not_received": "Have not received the code?",
        "page.consent.OTP_resend": "Resend",
        "page.block.add": "Add Block",
        "page.block.clear_edit": "Clear Your Editing",
        "page.block.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",
        "page.block.edit": "Edit Block",
        "page.block.preview": "Preview",
        "page.block.delete": "Delete Block: {title}",
        "page.block.delete_confirmation": "You cannot restore this block after delete. Are you sure to delete?",
        "page.block.empty": "No blocks are added up to now.",
        "page.block.load_more": "Load More",
        "page.block.view.add": "Add View for the block",
        "page.block.view.clear_edit": "Clear Your Editing",
        "page.block.view.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",
        "page.block.view.edit": "Edit Block View",
        "page.block.view.preview": "Preview",
        "page.block.view.delete": "Delete Block View: {title}",
        "page.block.view.delete_confirmation": "You cannot restore this block view after delete. Are you sure to delete?",
        "page.block.view.empty": "No block views are added up to now.",
        "page.block.view.load_more": "Load More",
        "page.table.view.add": "Add New Table View",
        "page.table.view.clear_edit": "Clear Your Editing",
        "page.table.view.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",
        "page.table.view.edit": "Edit Table View",
        "page.table.view.preview": "Preview",
        "page.table.view.delete": "Delete Table View: {title}",
        "page.table.view.delete_confirmation": "You cannot restore this table view after delete. Are you sure to delete?",
        "page.table.view.empty": "No table views are added up to now.",
        "page.table.view.load_more": "Load More",
        "page.project.add": "Add New Project",
        "page.project.edit": "Edit Project",
        "page.project.preview": "Preview",
        "page.project.delete": "Delete Project: {title}",
        "page.project.component.count": "{count} components",
        "page.project.delete_confirmation": "You cannot restore this project after delete. Are you sure to delete?",
        "page.project.new.button": "New Project",
        "page.project.new.subheading": "Start a brand new project.",
        "page.project.empty": "No projects are added up to now.",
        "page.project.load_more": "Load More",
        "page.project.clear_edit": "Clear Your Editing",
        "page.project.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",
        "page.project.wizard.navigation.welcome": "Get Started",
        "page.project.wizard.navigation.select_components": "Select Components",
        "page.project.wizard.navigation.customize_components": "Customize Components",
        "page.project.wizard.navigation.customize_components.configure": "Configure",
        "page.project.wizard.navigation.customize_components.ui": "Choose look and feel",
        "page.project.wizard.navigation.finish": "Finish",
        "page.project.wizard.welcome.title": "Let's get started.",
        "page.project.wizard.welcome.title.default": "New Project",
        "page.project.wizard.welcome.title.link_google": "You must first link a Google account to enable sheet access.",
        "page.project.wizard.welcome.description": "First, what should we call your new project?",
        "page.project.wizard.component.title": "Next, select the number of Table View components needed for this project.",
        "page.project.wizard.component.description": "Each component represents individual components to be included to your React project. You can always add or remove more afterwards.",
        "page.project.wizard.configure.title": "Configure {title}",
        "page.project.wizard.configure.title.default_name": "Component {index}",
        "page.project.wizard.look_and_feel.title": "Customize the looks for {title}",
        "page.project.wizard.look_and_feel.description": "Configure how your component will be displayed to your users.",
        "page.project.wizard.look_and_feel.theme": "Choose a theme",
        "page.project.wizard.look_and_feel.style": "Select a visual style for your selected theme",
        "page.project.wizard.look_and_feel.template_field_mapping": "Select template fields to map to your data",
        "page.project.wizard.look_and_feel.pagination": "How should your data be paged, if needed?",
        "page.project.wizard.look_and_feel.sort_by": "Choose the order in which your data should be displayed",
        "component.view.type.title": "Choose how your data is displayed as",
        "page.project.wizard.configure.description": "Connect and configure data for {title}",
        "page.project.wizard.close.confirm": "Are you sure you'd like to exit this wizard? All unsaved progress will be lost.",
        "page.project.wizard.finish.title": "Your project is now ready to be created",
        "page.project.wizard.finish.description": "Click on the button below to create your new project.",
        "page.project.component.example.title": "Field data",
        "page.project.component.example.title.description": "This is how your data will be formatted as",
        "page.project.dashboard": "Dashboard",
        "page.project.component.overview": "Overview",
        "page.project.component.data": "Data",
        "page.project.settings": "Settings",
        "page.project.component.look_and_feel": "Look and feel",
        "data.source.spreadsheet.component.title.title": "Title",
        "data.source.spreadsheet.component.title": "Choose a name for your component",
        "data.source.spreadsheet.select": "Select spreadsheet document",
        "data.source.spreadsheet.select.title": "Data Source",
        "data.source.spreadsheet.select.sheet.title": "Sheet",
        "data.source.spreadsheet.select.sheet": "Select a sheet from the spreadsheet",
        "data.source.open": "Open data source",
        "data.source.spreadsheet.configure.title": "Configure Fields",
        "data.source.spreadsheet.configure": "Configure sheet data",
        "data.source.spreadsheet.configure.column_number": "#",
        "data.source.spreadsheet.configure.column_name": "Name",
        "data.source.spreadsheet.configure.display_as": "Display As",
        "data.source.spreadsheet.configure.example": "Example",
        "data.source.spreadsheet.configure.visible": "Show",
        "page.article.add": "Add Article",
        "page.article.edit": "Edit Article",
        "page.article.preview": "Preview",
        "page.article.delete": "Delete Article: {title}",
        "page.article.delete_confirmation": "You cannot restore this article after delete. Are you sure to delete?",
        "page.article.empty": "No articles are added up to now.",
        "page.article.load_more": "Load More",
        "page.article.clear_edit": "Clear Your Editing",
        "page.article.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",

        "page.calendar.add": "Add Calendar",
        "page.calendar.edit": "Edit Calendar",
        "page.calendar.preview": "Preview",
        "page.calendar.delete": "Delete Calendar: {title}",
        "page.calendar.delete_confirmation": "You cannot restore this calendar after delete. Are you sure to delete?",
        "page.calendar.empty": "No calendars are added up to now.",
        "page.calendar.load_more": "Load More",
        "page.calendar.clear_edit": "Clear Your Calendar",
        "page.calendar.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",


        "page.uploader.add": "Add Uploader",
        "page.uploader.edit": "Edit Uploader",
        "page.uploader.preview": "Preview",
        "page.uploader.delete": "Delete Uploader: {name}",
        "page.uploader.delete_confirmation": "Your storage bucket will be deleted permanently and all associated files be purged immediately. Are you sure you would like to delete this bucket now?",
        "page.uploader.empty": "No uploader are added up to now.",
        "page.uploader.load_more": "Load More",
        "page.uploader.clear_edit": "Clear Your Editing",
        "page.uploader.clear_edit_confirmation": "You cannot restore your editing after clear. Are you sure to clear?",

        "page.insert_image.title": "Insert Image",
        "page.insert_image.fill_description": "Image Description",
        "page.insert_image.fill_link": "Image Link",
        "page.insert_image.upload": "Upload from Disk",
        "page.notification.event_comment": " replied your",
        "page.notification.event_like": " liked your",
        "page.notification.event_unlike": " cancelled like on your",
        "page.notification.event_mention": " mentioned you in his/her",
        "page.notification.object_article": " article ",
        "page.notification.object_comment": " comment ",
        "page.notification.object_thread": " thread ",
        "page.notification.empty": "No new notification.",
        "page.notification.load_all": "See all read notifications",
        "page.notification.set_as_read": "Mark as read",

        // Models.
        // pattern: <model_name>.<model_property>.<model_property_values>
        "user.email": "Email",
        "user.password": "Password",
        "user.confirm_password": "Confirm Password",
        "user.old_password": "Old Password",
        "user.new_password": "New Password",
        "user.name": "Name",
        "user.photo": "Photo",
        "user.gender": "Gender",
        "user.gender.male": "Male",
        "user.gender.female": "Female",
        "user.gender.other": "Other",
        "user.address": "Address",
        "user.website": "Web site",
        "user.OTP": "Confirmation code (Case-insensitive)",
        "user.invitation_code": "Invitation Code (Case-sensitive)",
        "preferences.editor_type": "Editor type",
        "preferences.editor_type.markdown": "Markdown",
        "preferences.editor_type.wysiwyg": "WYSIWYG",
        "preferences.dialog.add_card.title": "Add a new card",
        "preferences.dialog.add_card.button": "Add a new payment method",
        "preferences.dialog.add_primary_card.confirm": "Are you sure you'd like to make the card ending {ending} as your primary card?",
        "preferences.dialog.delete_primary_card.confirm": "Are you sure you'd like to delete the card ending {ending} from your payment methods?",
        "uploader.name": "Name",
        "uploader.accepts": "Accepted file types",
        "uploader.file_count": "Number of files allowed",
        "uploader.general_compress": "Compress files",
        "uploader.upload_limit": "File size limit (in KBs)",
        "uploader.auth_provider": "Authentication provider",
        "uploader.auth_config": "Authentication provider's config",
        "article.title": "Title",
        "article.content": "Content",
        "article.content_placeholder": `no less than ${ARTICLE_CONTENT_MIN_LENGTH} characters`,
        "calendar.name": "Name",
        "calendar.description": "Description",
        "calendar.description_placeholder": "Type description here",
        "block.title": "Title",
        "block.data_source": "Data Source",
        "block.sharing_type": "Privacy Level",
        "block.view.type": "Display As",
        "block.view.pagination_style":  "Pagination Style",
        "block.view.content_type": "Type of content",
        "block.view.comments_enabled": "Allow comments",
        "block.view.reviews_enabled": "Allow reviews",
        "post.created_at": "Created at ",
        "post.updated_at": "Last updated at ",
        "post.replied_at": "Last replied at ",
        "post.no_reply_yet": "No reply yet",
        "project.title": "Title",
        "project.description": "Content",
        "project.description_placeholder": `no less than ${ARTICLE_CONTENT_MIN_LENGTH} characters`,
        "project.navigation.add_component": "Add a new component",
        "project.component.style.view_type": "View Type",
        "project.component.style.view_type.description": "This is how your data is displayed to your users",
        "project.component.style.template": "Template",
        "project.component.style.template.description": "Select a style theme of how your data is presented to your users",
        "project.component.style.style": "Appearance",
        "project.component.style.style.description": "Select a visual style for your selected theme",
        "project.component.style.template_mapping": "Template Fields",
        "project.component.style.template_mapping.description": "Select which columns of data should be used for fields set by your template",
        "project.component.style.pagination": "Pagination Style",
        "project.component.style.pagination.description": "How long list of data should be paginated",
        "project.component.style.sort_by": "Sort By",
        "project.component.style.sort_by.description": "Choose the order in which your data should be displayed",
        "project.component.delete": "Delete Component",
        "project.component.delete.description": "WARNING: This is a dangerous and irreversible action.",
        "project.component.delete.text": "WARNING: Deleting a component is a permanent and irreversible action. Once you delete a component, you will immediately and permanently lose access to the component and the data associated with it.",
        "project.component.delete.dialog.text": "Are you sure you would like to delete your component?",
        "project.component.instruction": "Instructions",
        "project.component.instruction.description": "How to embed your component to your site",
        "project.component.publish.dialog.text": "Are you sure you would like to publish your changes to live?",
        "project.component.dashboard.traffic": "Total Traffic",
        "project.component.dashboard.component.traffic": "Component Traffic",
        "project.delete": "Delete Project",
        "project.delete.description": "WARNING: This is a dangerous and irreversible action",
        "project.delete.text": "WARNING: Deleting a project is a permanent and irreversible action. Once you delete this project, this project and all its components will cease to work immediately.",
        "project.delete.dialog.text": "Are you sure you would like to delete this project?",
        "table.view.project_id": "Project Id",
        "table.view.title": "(Optional) Add a title",
        "table.view.type": "Choose data source",
        "table.view.pagination_style": "Choose pagination style",
        "table.view.view_type": "Display as",

        // Components.
        // pattern: component.<component_name>.<action>
        "component.button.file_select": "Choose File",
        "component.button.login": "Sign in",
        "component.button.login.email": "Sign in with email",
        "component.button.signup": "Sign up",
        "component.button.submit": "Submit",
        "component.button.confirm": "OK",
        "component.button.cancel": "Cancel",
        "component.button.approve": "Approve",
        "component.button.deny": "Deny",
        "component.button.update": "Update",
        "component.button.delete": "Delete",
        "component.button.make_primary": "Make Primary",
        "component.button.edit": "Edit",
        "component.button.preview": "Preview",
        "component.button.see_all": "Read all",
        "component.button.create": "Create",
        "component.button.next": "Next",
        "component.button.previous": "Previous",
        "component.button.open": "Open",
        "component.button.close": "Close",
        "component.button.finish": "Finish",
        "component.button.scroll_up": "Scroll to top",
        "component.button.clear_edit": "Clear edit",
        "component.button.refresh": "Refresh",
        "component.button.refreshing": "Refreshing",
        "component.comment.title": "Comment",
        "component.comment.private": "Show after login",
        "component.comment.placeholder": "Leave a reply",
        "component.comment.submit": "Add Reply",
        "component.comment.reply": "Reply",
        "component.comment.delete": "Delete",
        "component.comment.delete_title": "Delete Comment",
        "component.comment.delete_confirmation": "You cannot restore this comment after delete. Are you sure to delete?",
        "component.footer.nothing_more": "No more articles",
        "component.block.data_source_select": "Select data source",
        "component.dropdown.data_source.managed": "Hosted",
        "component.dropdown.data_source.others": "External",
        "component.dropdown.data_source_field_type.boolean": "Boolean",
        "component.dropdown.data_source_field_type.text": "Text",
        "component.dropdown.data_source_field_type.number": "Number",
        "component.dropdown.data_source_field_type.datetime": "DateTime",
        "component.dropdown.data_source_field_type.currency": "Currency",
        "component.dropdown.data_source_field_type.percent": "Percent",
        "component.dropdown.data_source_field_type.link": "Link",
        "component.dropdown.data_source_field_type.email": "Email",
        "component.dropdown.data_source_field_type.latlng": "Lat/Lng",
        "component.dropdown.data_source_field_type.address": "Address",
        "component.block.sharing_type_select": "Select sharing type",
        "component.dropdown.sharing_type.private": "Private",
        "component.dropdown.sharing_type.read_only": "Read-only",
        "component.dropdown.sharing_type.content_moderated": "Moderated per posting",
        "component.dropdown.sharing_type.access_moderated": "Let me choose who has access",
        "component.dropdown.sharing_type.open": "Public",
        "component.dropdown.auth_provider_type.FIREBASE": "Firebase",
        "component.dropdown.auth_provider_type.CLERK": "Clerk",
        "component.dropdown.auth_provider_type.CUSTOM": "Custom",
        "component.dropdown.auth_provider_type.NONE": "No authentication",
        "component.dropdown.compression_option_type.ALL": "Compress all files",
        "component.dropdown.compression_option_type.NONE": "No compression",
        "component.dropdown.accepted_file_type.IMAGE": "Images (.jpg|.png|.gif)",
        "component.dropdown.accepted_file_type.ALL": "Any files",
        "component.table.view.view_type_select": "Select View Style",
        "component.dropdown.table_view_type.table_view_list": "View as a list",
        "component.dropdown.table_view_type.table_view_gallery": "View as a gallery",
        "component.table.view.table_view_type_select": "Select Table View's Data Source",
        "component.dropdown.table_view_type.table_view_google_sheet": "Google Sheet",
        "component.table.view.pagination_style_select": "Select Pagination Style",
        "component.dropdown.pagination_style.pagination_paged": "Paged",
        "component.dropdown.pagination_style.pagination_infinite": "Infinite Scroll",
        "component.dropdown.pagination_style.pagination_none": "Show All",
        "component.button.login.with.google": "Sign in with Google",
        "component.button.link.with.google": "Link with Google",
        "component.dropdown.sort_by_select": "Select how entries are sorted",
        "component.dropdown.sort_by.recent": "Show last entries first",
        "component.dropdown.sort_by.oldest": "Show first entries first",
        "component.view_type.label.board": "Board",
        "component.view_type.label.list": "List",
        "component.view_type.label.gallery": "Gallery",
        "component.view_type.label.graph": "Graph",
        "component.view_type.label.table": "Table",
        "component.view_type.label.map": "Map",
        "component.view_type.label.nearby": "Nearby",
        "component.view_type.label.spotlight": "Spotlight",

        // Toasts.
        // pattern: toast.<model>.<info>
        "toast.user.general_error": "Cannot find the user, please check.",
        "toast.user.invalid_token_error": "Please log in first.",
        "toast.user.sign_in_successfully": "Sign in successfully.",
        "toast.user.sign_up_successfully": "Sign up successfully.",
        "toast.user.sign_in_failed": "Sign in failed.",
        "toast.user.deny_consent": "Please approve to finish signing up.",
        "toast.user.update_successfully": "Update success.",
        "toast.user.update_failed": "Update failed.",
        "toast.user.upload_avatar_failed": "Upload avatar failed.",
        "toast.user.upload_exist_account": "Account with that email address already exists.",
        "toast.user.account_not_found": "Account cannot be found.",
        "toast.user.error_OTP": "There is an error with your confirmation code!",
        "toast.user.expired_OTP": "Your confirmation code is invalid or expired!",
        "toast.user.password_not_change": "New password cannot be the same as the old one.",
        "toast.user.old_password_error": "Old password is incorrect.",
        "toast.client.invalid": "Invalid client!",
        "toast.client.incorrect_url": "Incorrect redirectUri!",
        "toast.post.title_empty": "Title could not be empty.",
        "toast.post.content_empty": "Content could not be empty.",
        "toast.thread.title_too_long": `Title could not be longer than ${THREAD_TITLE_MAX_LENGTH} characters.`,
        "toast.thread.content_too_long": `Thread could not be longer than ${THREAD_CONTENT_MAX_LENGTH} characters.`,
        "toast.article.title_too_long": `Title could not be longer than ${ARTICLE_TITLE_MAX_LENGTH} characters.`,
        "toast.article.content_too_short": `Article could not be shorter than ${ARTICLE_CONTENT_MIN_LENGTH} characters.`,
        "toast.article.content_too_long": `Article could not be longer than ${ARTICLE_CONTENT_MAX_LENGTH} characters.`,
        "toast.article.save_successfully": "Save your article successfully.",
        "toast.article.delete_successfully": "Delete your article successfully.",
        "toast.article.invalid_author": "You are not the author!",
        "toast.article.not_found": "Article not found!",

        "toast.calendar.title_too_long": `Title could not be longer than ${ARTICLE_TITLE_MAX_LENGTH} characters.`,
        "toast.calendar.content_too_short": `Calendar could not be shorter than ${ARTICLE_CONTENT_MIN_LENGTH} characters.`,
        "toast.calendar.content_too_long": `Calendar could not be longer than ${ARTICLE_CONTENT_MAX_LENGTH} characters.`,
        "toast.calendar.save_successfully": "Save your calendar successfully.",
        "toast.calendar.delete_successfully": "Delete your calendar successfully.",
        "toast.calendar.invalid_author": "You are not the author!",
        "toast.calendar.not_found": "Calendar not found!",


        "toast.uploader.name_empty": `Uploader name could not be shorter than ${UPLOADER_NAME_MIN_LENGTH} characters.`,
        "toast.uploader.name_too_small": `Uploader name could not be shorter than ${UPLOADER_NAME_MIN_LENGTH} characters.`,
        "toast.uploader.content_too_short": `Uploader could not be shorter than ${UPLOADER_NAME_MIN_LENGTH} characters.`,
        "toast.uploader.save_successfully": "Saved your uploader successfully.",
        "toast.uploader.delete_successfully": "Deleted your uploader successfully.",
        "toast.uploader.invalid_author": "You are not the author!",
        "toast.uploader.not_found": "Article not found!",
        "toast.block.not_found": "Block not found!",
        "toast.block.save_successfully": "Your block has been successfully saved.",
        "toast.block.delete_successfully": "Your block is now deleted.",
        "toast.user.attack_alert": "Malicious attack is detected.",
        "toast.user.email": "Invalid email.",
        "toast.user.email_not_found": "This email is not found.",
        "toast.user.password_error": "Password is incorrect.",
        "toast.user.password_too_short": `Password should be longer than or equal to ${PASSWORD_MIN_LENGTH} characters.`,
        "toast.user.password_empty": "Password could not be empty.",
        "toast.user.confirm_password": "confirmed password field must have the same value as the password field",
        "toast.user.name": "Name could not be empty.",
        "toast.user.gender": "Invalid gender.",
        "toast.user.otp_send_failed": "There was an error sending a confirmation code!",
        "toast.user.preferences.editor_type": "Invalid editor type",
        "toast.user.invitation_code.empty": "Invitation code cannot be empty",
        "toast.user.invitation_code.invalid": "Invalid invitation code",
        "toast.user.invitation_code.used": "Used invitation code",
        "toast.comment.content_empty": "Comment could not be empty.",
        "toast.comment.add_successfully": "Comment successfully.",
        "toast.comment.add_failed": "Comment failed.",
        "toast.comment.delete_parent": "Sorry, you cannot delete a comment someone has replied to it.",
        "toast.comment.delete_successfully": "Delete successfully.",
        "toast.comment.delete_failed": "Delete failed.",
        "toast.comment.not_found": "Cannot find this comment.",
        "toast.notification.not_found": "Cannot find this notification",
        "toast.thread.add_successfully": "Your thread created.",
        "toast.thread.add_failed": "Your thread cannot be created.",
        "toast.thread.delete_successfully": "Your thread has been deleted",
        "toast.thread.delete_failed": "Your thread cannot be deleted.",
        "toast.post.insert_image_failed": "Failed to insert the image",
        "toast.view.table.save_successfully": "Your table view component has been successfully saved.",
        "toast.view.table.delete_successfully": "Delete your table view successfully.",
        "toast.view.table.not_found": "Component is not found",
        "toast.project.delete_successfully": "Your project has been deleted",
        "toast.project.not_found": "Project not found!",
        "toast.project.save_successfully": "Your project has been successfully saved.",
        "toast.text.copied": "Text copied to clipboard",
        // generics
        "get_started": "Get Started"
    }
};

export default TRANSLATION;