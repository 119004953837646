import React, { RefObject } from "react";
import connectAllProps from "../../../shared/connect";
import { MessageDescriptor } from "react-intl";
import { ComponentProps } from "../../../shared/ComponentProps";
import { PrimitiveType } from "intl-messageformat";
import { EmailTemplate } from "@airjam/types";
import { Grid } from "semantic-ui-react";

interface Props extends ComponentProps {
    key: string;
    template: EmailTemplate;
    defaultTemplate: EmailTemplate;
    onTemplateUpdated?: (updatedTemplate: EmailTemplate) => void;
}

interface States {}

class EmailTemplateEditor extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private subjectRef: RefObject<HTMLInputElement>;
    private contentRef: RefObject<HTMLTextAreaElement>;
    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.subjectRef = React.createRef();
        this.contentRef = React.createRef();
    }
    render(): React.ReactElement<any> {
        return <div key={this.props.key}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>Name</Grid.Column>
                    <Grid.Column width={13}>{this.props.defaultTemplate ? this.props.defaultTemplate.name : ""}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>Description</Grid.Column>
                    <Grid.Column width={13}>{this.props.defaultTemplate ? this.props.defaultTemplate.description : ""}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>Subject</Grid.Column>
                    <Grid.Column width={13}>
                        <input ref={this.subjectRef} defaultValue={this.props.template && this.props.template.subject ? this.props.template.subject : this.props.defaultTemplate.subject} onChange={() => this.onEditing()}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>Content</Grid.Column>
                    <Grid.Column width={13}>
                        <textarea ref={this.contentRef} defaultValue={this.props.template && this.props.template.content ? this.props.template.content : this.props.defaultTemplate.content} onChange={() => this.onEditing()}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>;
    }

    private onEditing = () => {
        if (this.props.onTemplateUpdated) {
            this.props.onTemplateUpdated({
                name: this.props.template && this.props.template.name ? this.props.template.name : this.props.defaultTemplate.name,
                description: this.props.template && this.props.template.description ? this.props.template.description : this.props.defaultTemplate.description,
                subject: this.subjectRef.current ? this.subjectRef.current.value : "",
                content: this.contentRef.current ? this.contentRef.current.value : "",
            } as EmailTemplate);
        }
    }
}

export default connectAllProps(EmailTemplateEditor);